// src/components/SettingDetails/UserManagement/UserRolesModal.js

import React from 'react';
import CustomModal from './Modal';

const UserRolesModal = ({ isOpen, onRequestClose, userRoles }) => {
    return (
        <CustomModal isOpen={isOpen} onRequestClose={onRequestClose}>
            <h5>User Roles</h5>
            {userRoles.length > 0 ? (
                <table style={tableStyle}>
                    <thead>
                        <tr style={tableHeaderStyle}>
                            <th style={cellStyle}>Access Rules</th>
                            <th style={cellStyle}>User Role</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userRoles.map((role, index) => (
                            <tr key={role.user_rls_id || index} style={tableRowStyle}>
                                <td style={cellStyle}>{role.access_rules}</td>
                                <td style={cellStyle}>{role.user_role}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No user roles found.</p>
            )}
        </CustomModal>
    );
};

// Styles for the table, headers, and rows
const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '20px',
    fontFamily: 'Arial, sans-serif',
    textAlign: 'left',
};

const tableHeaderStyle = {
    backgroundColor: '#f5f5f5',
    color: '#333',
    fontWeight: 'bold',
    borderBottom: '2px solid #CCC',
};

const tableRowStyle = {
    borderBottom: '1px solid #EEE',
    transition: 'background-color 0.3s ease', // Adds hover transition
};

const cellStyle = {
    border: '1px solid #CCC',
    padding: '10px',
};

export default UserRolesModal;
