import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Slider from "react-slick";
import { Dialog, DialogContent, Button } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../assets/css/intro-tour.css";
import FinishModal from "./FinishModal";

import {
    setFlag,
} from '../../store/actions';


const IntroTour = ({ steps }) => {
    const activeUser = useSelector((state) => state.activeUser);
    const activeUserName = activeUser.username;

    const [isOpen, setIsOpen] = useState(true);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [selectedCard, setSelectedCard] = useState(null); // Track selected card index
    const sliderRef = React.useRef(null);
    const [showFinishModal, setShowFinishModal] = useState(false); // State for the sidebar modal

    const dispatch = useDispatch();

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleOpenFinishModal = () => {
        dispatch(setFlag('isSidebarCollapsed', true));
        setIsOpen(false); // Close the main modal
        setShowFinishModal(true); // Open the sidebar modal
    };

    const handleNext = () => {
        if (currentSlide < steps.length - 1) {
            sliderRef.current.slickNext();
        } else {
            handleOpenFinishModal(); // Open the sidebar modal when the tour ends
        }
    };

    const handleCardSelect = (index) => {
        // Set the selected card only when the 'select' icon is clicked
        setSelectedCard(index);
    };

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        adaptiveHeight: true,
        beforeChange: (_, next) => setCurrentSlide(next),
        customPaging: (i) => (
            <div
                style={{
                    width: currentSlide === i ? "15px" : "6px",
                    height: "6px",

                    cursor: "pointer",
                    display: "inline-block",
                    transition: "width .1s ease-in",
                    borderRadius: "1000px",
                    backgroundColor: currentSlide === i ? "#ffc107" : "#e0e0e0",
                    marginLeft: "2.5px"


                }}
            />
        ),
        appendDots: (dots) => (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    gap: "6px",
                }}
            >
                <div style={{ display: "flex", justifyContent: "space-between", maxWidth: "max-content" }}>
                    <div
                        style={{
                            fontSize: "12px",
                            fontFamily: "Nunito, sans-serif",
                            color: "#9e9e9e",
                            display: "flex",
                            alignItems: "flex-end",

                        }}
                    >
                        {currentSlide + 1} of {steps.length}
                    </div>

                    {/* Dots */}
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            marginTop: "14px",
                            marginLeft: "6px",



                        }}
                    >
                        {dots}
                    </div>
                </div>
            </div>
        ),


    };


    const renderSpecialContent = (content) => {
        if (content === "Streamline your workflow and complete tasks step-by-step with <br /> guided AI assistance") {
            const cardOptions = [
                "Retrieve data and <br /> generate AI-powered <br /> reports",
                "Interact with and    <br /> process documents <br /> seamlessly",
                // "Complete tasks <br />efficiently with guided AI <br />assistance.",
                "Visualize data and <br /> explore insights on a <br />map",
            ];

            return (
                <div
                    className="row"
                    style={{
                        minHeight: "185px",
                        display: "flex",
                        flexWrap: "nowrap", // Ensure all cards stay on the same line
                        justifyContent: "center", // Center all cards horizontally
                        alignItems: "center", // Align cards vertically if needed
                        gap: "5px", // Equal spacing between cards

                    }}
                >
                    {cardOptions.map((text, index) => (
                        <div key={index} className="col-auto">
                            <div
                                className="card bg-intro p-2 d-flex justify-content-center align-items-center"
                                style={{
                                    // width: "8rem", // Ensure all cards have the same width
                                    textAlign: "center",
                                }}
                            >
                                {/* Radio button for selection */}
                                <div
                                    className="cus-rad align-self-center intro-chk"
                                    style={{
                                        width: "30px",
                                        height: "30px",
                                        border: "1px solid #F8EAC1",
                                        borderRadius: "50%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        backgroundColor: "#fff",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => handleCardSelect(index)}
                                >
                                    <i
                                        className="fas fa-check-circle text-grn font-size-18"
                                        style={{
                                            display:
                                                selectedCard === index ||
                                                    (selectedCard === null && index === 0)
                                                    ? "block"
                                                    : "none", // Default check for the first checkbox if none is selected
                                            color: "#14BFB4",
                                        }}
                                    ></i>
                                </div>
                                {/* Display content */}
                                <p
                                    className="mb-0 font-size-12 text-dark pt-2 pb-2"
                                    dangerouslySetInnerHTML={{ __html: text }}
                                ></p>
                            </div>
                        </div>
                    ))}
                </div>

            );
        }
        return null;
    };



    return (
        <>
            {/* Main Tour Dialog */}
            {isOpen && (
                <Dialog
                    open={isOpen}
                    maxWidth="md"
                    PaperProps={{
                        style: {
                            borderRadius: "12px",
                            padding: "16px",
                            maxWidth: "600px",
                            width: "100%",
                        },
                    }}
                >
                    {/* Header */}
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            // marginBottom: "16px",
                        }}
                    >
                        <img
                            src="/assets/images/wizardblacklogo.svg"
                            alt="Logo"
                            style={{ height: "28px" }}
                        />
                        <h6 className="mb-0 fw-medium cursor-pointer" onClick={handleClose}>
                            Skip Tour
                        </h6>
                    </div>

                    {/* Carousel */}
                    <DialogContent className="auth-bg intro-inner-border intro-wel-bg min-w-650px min-h-450px mt-3">
                        <Slider {...settings} ref={sliderRef}>
                            {steps.map((step, index) => (
                                <div key={index} style={{ textAlign: "center" }}>
                                    <p className="fontpopins" style={{
                                        textAlign: "center"
                                    }}>
                                        {step.title.replace("{activeUserName}", activeUserName)}
                                    </p>

                                    {/* Conditional rendering for specific content */}
                                    {step.content === "Let’s explore your AI assistant’s <br />capabilities." ? (
                                        <>
                                            <p className="fontpopinstwo"
                                                style={{

                                                    color: "#333",
                                                    marginBottom: "24px",
                                                    textAlign: "center",



                                                }}
                                                dangerouslySetInnerHTML={{ __html: step.content }}
                                            ></p>

                                            {step.image && (
                                                <img
                                                    src={step.image}
                                                    alt="Centered Image"
                                                    className="responsive-image"
                                                    style={{
                                                        width: step.width,
                                                        height: step.height,
                                                        marginBottom: "24px",
                                                        textAlign: "center",
                                                        marginLeft: step.marginLeft,

                                                    }}
                                                />
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {step.image && (
                                                <img
                                                    src={step.image}
                                                    alt="Centered Image"
                                                    style={{
                                                        width: step.width,
                                                        height: step.height,
                                                        marginBottom: "24px",
                                                        textAlign: "center",
                                                        marginLeft: step.marginLeft,
                                                    }}
                                                />
                                            )}

                                            <p
                                                style={{
                                                    fontSize: "16px",
                                                    color: "#4a4a4a",
                                                    marginBottom: "24px",
                                                    textAlign: "center",
                                                }}
                                                dangerouslySetInnerHTML={{ __html: step.content }}
                                            ></p>
                                        </>
                                    )}

                                    {renderSpecialContent(step.content)}
                                </div>
                            ))}
                        </Slider>
                        <div style={{ display: "flex", justifyContent: "center", marginTop: "40px", gap: "16px" }}>
                            {currentSlide < steps.length - 1 && (
                                <Button
                                    onClick={handleNext}
                                    variant="contained"
                                    style={{
                                        backgroundColor: "#FFD13C",
                                        color: "#000",
                                        textTransform: "none",
                                        padding: "8px 24px",

                                    }}
                                >
                                    Next
                                </Button>
                            )}
                            {currentSlide === steps.length - 1 && (

                                <Button
                                    onClick={handleOpenFinishModal}
                                    variant="contained"
                                    style={{
                                        backgroundColor: "#FFD13C",
                                        color: "#000",
                                        textTransform: "none",
                                        padding: "8px 24px",

                                    }}
                                >
                                    Finish
                                </Button>
                            )}
                        </div>

                    </DialogContent>

                    {/* Footer */}

                </Dialog>

            )}
            <FinishModal
                isOpen={showFinishModal}
                onClose={() => setShowFinishModal(false)}
            />
        </>
    );
};





// Example usage
const ExamplePage = () => {
    const tourSteps = [
        {
            title: "Welcome, {activeUserName}!",
            content: "Let’s explore your AI assistant’s <br />capabilities.",
            image: "/assets/images/1.gif",

            height: "140px",
            marginLeft: "38%",
        },
        {
            title: "Q&A",
            content: "Quickly retrieve data and get<br />AI-powered insights.",
            image: "/assets/images/4.gif",

            height: "150px",
            marginLeft: "12%",
        },
        {
            title: "DocPro",
            content: "Manage document retrieval<br />and processing seamlessly.",
            image: "/assets/images/3.gif",

            height: "150px",
            marginLeft: "6%",
        },
        // {
        //     title: "Task",
        //     content:
        //         "Streamline your workflow and complete tasks step-by-step<br />with guided AI assistance.",
        //     image: "/assets/images/4.gif",
        //     width: "40%",
        //     height: "200px",
        //     marginLeft: "25%",
        // },
        {
            title: "What will you primarily use Wizar.dopplr for?",
            content:
                "Streamline your workflow and complete tasks step-by-step with <br /> guided AI assistance",
            image: "",
            width: "40%",
            height: "150px",
            marginLeft: "25%",
        },
    ];

    return (
        <div>
            <IntroTour steps={tourSteps} />
        </div>
    );
};

export default ExamplePage;
