import React from 'react'

const DBManagement = () => {
    return (
        <>
            <div>DB Connection</div>
            <div>Metadata Management</div>
        </>
    )
}

export default DBManagement;