// src/components/SettingDetails/UserManagement/UserUserEditModal.js

import React, { useState, useEffect } from 'react';
import CustomModal from './Modal';
import { updateUserDetails } from '../../../services/chatService';
import { useSelector } from 'react-redux';

const UserEditModal = ({ isOpen, onRequestClose, currentUser, activeUserName, handleUserUpdate, userRoles }) => {
    const [formData, setFormData] = useState({
        fullname: '',
        username: '',
        password: '',
        confirmPassword: '',
        initial: '',
        user_role: ''
    });

    // Populate form with current user details when modal opens
    useEffect(() => {
        if (currentUser) {
            setFormData({
                fullname: currentUser.name || '', // Assuming fullname is stored as 'name'
                username: currentUser.username,
                password: '',  // Don't auto-fill password fields
                confirmPassword: '',
                initial: currentUser.initial || '', // Assuming initial exists in the user object
                user_role: currentUser.user_role
            });
        }
    }, [currentUser]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [id]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the form from refreshing the page

        // Validate that passwords match
        if (formData.password && formData.password !== formData.confirmPassword) {
            alert("Passwords do not match!");
            return;
        }

        const editedUserInfo = {
            name: formData.fullname,
            username: formData.username,
            initial: formData.initial,
            user_role: formData.user_role,
            ...(formData.password && { password: formData.password }) // Only include password if it has been changed
        };

        // Temporary removal of 'created_by' from currentUser
        const { created_by, id, ...onlyNecessaryFields } = currentUser;

        console.log("Before API call");
        console.log("onlyNecessaryFields:", onlyNecessaryFields);
        console.log("editedUserInfo:", editedUserInfo);

        // Combine the old and new objects into a single object to be sent to the backend
        const payload = {
            old_info: onlyNecessaryFields,
            new_info: editedUserInfo,
            updated_by: activeUserName
        };

        try {
            const response = await updateUserDetails(payload);
            // Send the updated data to the parent component
            alert(response.message);
            handleUserUpdate(editedUserInfo);
            onRequestClose();  // Close the modal after saving
        } catch (error) {
            console.error("Error updating user:", error);
        }
    };

    return (
        <CustomModal isOpen={isOpen} onRequestClose={onRequestClose}>
            <h4>Edit User</h4>

            {/* Wrapping inputs inside a form */}
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Full Name"
                    id="fullname"
                    value={formData.fullname}
                    onChange={handleInputChange}
                    style={{ marginBottom: '20px' }}
                />
                <br />
                <input
                    type="text"
                    placeholder="Username"
                    id="username"
                    value={formData.username}
                    onChange={handleInputChange}
                    style={{ marginBottom: '20px' }}
                />
                <br />
                <input
                    type="password"
                    placeholder="Enter a new password (leave blank to keep current)"
                    id="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    style={{ marginBottom: '20px' }}
                    autoComplete="off" // Prevents autofill by the browser
                />
                <br />
                <input
                    type="password"
                    placeholder="Confirm new password"
                    id="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                    style={{ marginBottom: '20px' }}
                    autoComplete="off"
                />
                <br />
                <input
                    type="text"
                    placeholder="Initial"
                    id="initial"
                    value={formData.initial}
                    onChange={handleInputChange}
                    style={{ marginBottom: '20px' }}
                />
                <br />
                {/* Dropdown for User Role */}
                <select
                    id="user_role"
                    value={formData.user_role}
                    onChange={handleInputChange}
                    style={{ marginBottom: '20px' }}
                >
                    <option value="">Select Role</option>
                    {userRoles.map((role, index) => (
                        <option key={index} value={role.user_role}>
                            {role.user_role}
                        </option>
                    ))}
                </select>
                <br />
                <button type="submit">Save</button>
            </form>
        </CustomModal>
    );
};

export default UserEditModal;
