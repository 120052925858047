// src/components/SettingDetails/Configuration.js

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Slider, FormControlLabel, Switch, Button, TextField } from '@mui/material';
import {
    setConfigurationInfo,
} from '../../store/actions';

import { AlertNotification } from '../../utils/AlertNotification';

const Configuration = () => {
    const dispatch = useDispatch();

    // Fetch values from Redux
    const currentConfigurationInfo = useSelector((state) => state.currentConfigurationInfo);

    const [length, setLength] = useState(150);
    const [temperature, setTemperature] = useState(0.7);
    const [includeInternetSearch, setIncludeInternetSearch] = useState(false);
    const [includeWebScraping, setIncludeWebScraping] = useState(false);
    const [webScrapingUrl, setWebScrapingUrl] = useState('');

    // ----------------------------------------------- Alert Notification
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('info');
    // -----------------------------------------------
    
    useEffect(() => {
        if (currentConfigurationInfo.length) {
            setLength(currentConfigurationInfo.length)
        }

        if (currentConfigurationInfo.temperature) {
            setTemperature(currentConfigurationInfo.temperature)
        }
    }, [currentConfigurationInfo]);

    const handleLengthChange = (event, newValue) => {
        setLength(newValue);
    };

    const handleTemperatureChange = (event, newValue) => {
        setTemperature(newValue);
    };

    const handleInternetSearchChange = (event) => {
        setIncludeInternetSearch(event.target.checked);
    };

    const handleWebScrapingChange = (event) => {
        setIncludeWebScraping(event.target.checked);
    };

    const handleWebScrapingUrlChange = (event) => {
        setWebScrapingUrl(event.target.value);
    };

    const handleApply = () => {
        console.log(length, temperature, includeInternetSearch, includeWebScraping, webScrapingUrl);
        dispatch(setConfigurationInfo(length, temperature, includeInternetSearch, includeWebScraping, webScrapingUrl));

        setAlertMessage('Settings changed successfully');
        setAlertType('success');
        setShowAlert(true);
    };

    return (
        <div className="tab-content pt-3" style={{ display: 'flex', justifyContent: 'center', paddingLeft: '15px', paddingRight: '15px', paddingBottom: '15px' }}>
            <div className="configuration-settings" style={{ backgroundColor: '#fff', borderRadius: '8px', padding: '20px', width: '100%', maxWidth: '600px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
                <Typography gutterBottom variant="h6" style={{ fontSize: '16px' }}>Response Length: {length}</Typography>
                <Slider
                    value={length}
                    onChange={handleLengthChange}
                    aria-labelledby="response-length-slider"
                    valueLabelDisplay="auto"
                    step={100}
                    marks
                    min={1000}
                    max={5000}
                />

                <Typography gutterBottom variant="h6" style={{ fontSize: '16px' }}>
                    Temperature: {(temperature || 0).toFixed(1)}
                </Typography>

                <Slider
                    value={temperature}
                    onChange={handleTemperatureChange}
                    aria-labelledby="temperature-slider"
                    valueLabelDisplay="auto"
                    step={0.1}
                    marks
                    min={0}
                    max={1}
                />
                <FormControlLabel
                    control={<Switch checked={includeInternetSearch} onChange={handleInternetSearchChange} name="includeInternetSearch" />}
                    label="Include Internet Search"
                />
                <FormControlLabel
                    control={<Switch checked={includeWebScraping} onChange={handleWebScrapingChange} name="includeWebScraping" />}
                    label="Include Web Scraping"
                />
                {includeWebScraping && (
                    <TextField
                        fullWidth
                        label="Web Scraping URL"
                        variant="outlined"
                        value={webScrapingUrl}
                        onChange={handleWebScrapingUrlChange}
                        style={{ marginTop: '16px' }}
                    />
                )}
                <br />
                <br />
                <Button onClick={handleApply} variant="contained" color="primary" style={{ marginTop: '16px' }}>
                    Apply
                </Button>
            </div>

            {showAlert && (
                <AlertNotification
                    message={alertMessage}
                    type={alertType}
                    onClose={() => setShowAlert(false)}
                    autoClose={true}
                    autoCloseTime={5000}
                />
            )}
        </div>
    );
};

export default Configuration;
