import React from 'react';
import { ChevronRight } from 'lucide-react';

// Simple Card component implementation using Tailwind
const Card = ({ className, children }) => (
    <div className={`bg-white rounded-lg border border-gray-200 shadow-sm ${className}`}>
        {children}
    </div>
);

const CardContent = ({ className = '', children }) => (
    <div className={`p-4 ${className}`}>
        {children}
    </div>
);

const CircularProgress = ({ value, color = 'green' }) => {
    const radius = 20;
    const circumference = 2 * Math.PI * radius;
    const strokeDashoffset = circumference - (value / 100) * circumference;

    return (
        <div className="relative inline-flex items-center justify-center">
            <svg className="w-16 h-16 transform -rotate-90">
                <circle
                    className="text-gray-200"
                    strokeWidth="4"
                    stroke="currentColor"
                    fill="transparent"
                    r={radius}
                    cx="32"
                    cy="32"
                />
                <circle
                    className={`text-${color}-500`}
                    strokeWidth="4"
                    strokeDasharray={circumference}
                    strokeDashoffset={strokeDashoffset}
                    strokeLinecap="round"
                    stroke="currentColor"
                    fill="transparent"
                    r={radius}
                    cx="32"
                    cy="32"
                />
            </svg>
            <span className="absolute text-sm font-medium">{value}%</span>
        </div>
    );
};

const KPICard = ({ title, value, suffix = '', progress, subtitle = '' }) => {
    return (
        <Card className="w-48">
            <CardContent className="pt-6">
                <div className="space-y-2">
                    <p className="text-sm text-gray-500">{title}</p>
                    <div className="flex items-center justify-between">
                        {progress ? (
                            <CircularProgress value={progress} />
                        ) : null}
                        <div className="text-right">
                            <p className="text-2xl font-semibold">
                                {value}
                                <span className="text-sm">{suffix}</span>
                            </p>
                            {subtitle && (
                                <p className="text-xs text-gray-500">{subtitle}</p>
                            )}
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

const ModalSettings = () => {
    // Sample data for KPI cards
    const kpis = [
        {
            title: 'My hours this week',
            value: '21:15',
            progress: 71
        },
        {
            title: 'My projects',
            value: '754.7',
            suffix: 'k',
            subtitle: 'Income (actual)'
        },
        {
            title: 'New leads this month',
            value: '42',
            progress: 48,
            subtitle: 'Total quotes'
        },
        {
            title: 'Outstanding invoices',
            value: '76.5',
            suffix: 'k',
            subtitle: 'Excl tax 3 invoices'
        },
        {
            title: 'Revenue this month',
            value: '829.7',
            suffix: 'k',
            subtitle: 'Sum (Excl tax)'
        },
        {
            title: 'Quoted this month',
            value: '919.5',
            suffix: 'k',
            subtitle: 'Sum (Excl tax)'
        }
    ];

    // Original metrics data
    const metrics = {
        custom: {
            companiesNameCheck: 0.11428571428571428
        },
        rag: {
            contextAdherence: 0.405,
            completeness: 0.697,
            attribution: 0.250,
            chunkUtilization: 0.046
        },
        output: {
            correctness: 0.167
        }
    };

    const tableData = [
        {
            nodeType: "RunnableSequence",
            input: "What was Costco's revenue...",
            output: "Costco's revenue in...",
            contextAdherence: "low",
            completeness: "high",
            attribution: "1 of 4",
            utilization: "low",
            correctness: "low",
            latency: "108,133 ms",
            cost: "$0.0014",
            pii: "None"
        }
    ];

    const getStatusColor = (status) => {
        switch (status.toLowerCase()) {
            case 'high': return 'text-green-500';
            case 'low': return 'text-red-500';
            case 'medium': return 'text-gray-500';
            default: return 'text-gray-700';
        }
    };

    const MetricSection = ({ title, children }) => (
        <div className="border border-gray-200 rounded-lg bg-white">
            <div className="border-b border-gray-200 px-4 py-3">
                <h3 className="text-sm font-medium text-gray-700">{title}</h3>
            </div>
            <div className="p-4">
                {children}
            </div>
        </div>
    );

    const MetricItem = ({ label, value }) => (
        <div className="flex items-center justify-between">
            <span className="text-sm text-gray-600">{label}</span>
            <span className="text-sm font-medium text-gray-900">
                {typeof value === 'number' ? (value * 100).toFixed(3) : value}
            </span>
        </div>
    );

    return (
        <div className="p-4 space-y-6">
            {/* KPI Cards Section */}
            <div className="flex flex-wrap gap-4 mb-6">
                {kpis.map((kpi, index) => (
                    <KPICard key={index} {...kpi} />
                ))}
            </div>

            {/* Original Metrics Section */}
            <div className="flex gap-4 mb-4">
                {/* Custom Metrics Card */}
                <div className="w-1/4">
                    <MetricSection title="Custom Metrics">
                        <MetricItem
                            label="Companies Name Check"
                            value={metrics.custom.companiesNameCheck}
                        />
                    </MetricSection>
                </div>

                {/* RAG Quality Card */}
                <div className="w-1/4">
                    <MetricSection title="RAG Quality">
                        <div className="space-y-2">
                            <MetricItem
                                label="Average Context Adherence"
                                value={metrics.rag.contextAdherence}
                            />
                            <MetricItem
                                label="Average Completeness"
                                value={metrics.rag.completeness}
                            />
                            <MetricItem
                                label="Average Attribution"
                                value={metrics.rag.attribution}
                            />
                            <MetricItem
                                label="Average Chunk Utilization"
                                value={metrics.rag.chunkUtilization}
                            />
                        </div>
                    </MetricSection>
                </div>

                {/* Output Quality Card */}
                <div className="w-1/4">
                    <MetricSection title="Output Quality">
                        <MetricItem
                            label="Average Correctness"
                            value={metrics.output.correctness}
                        />
                    </MetricSection>
                </div>
            </div>

            {/* Table */}
            <div className="border border-gray-200 rounded-lg bg-white">
                <div className="overflow-x-auto">
                    <table className="min-w-full">
                        <thead>
                            <tr className="bg-gray-50 border-b border-gray-200">
                                <th className="w-12 px-4 py-3"></th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Node Type</th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Node Input</th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Node Output</th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Context Adherence</th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Completeness</th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Attribution</th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Utilization</th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Correctness</th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Latency</th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Cost</th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">PII</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((row, index) => (
                                <tr key={index} className="border-b border-gray-200">
                                    <td className="px-4 py-3">
                                        <ChevronRight className="h-4 w-4 text-gray-500" />
                                    </td>
                                    <td className="px-4 py-3 text-sm text-gray-900">{row.nodeType}</td>
                                    <td className="px-4 py-3 text-sm text-gray-900 max-w-xs truncate">{row.input}</td>
                                    <td className="px-4 py-3 text-sm text-gray-900 max-w-xs truncate">{row.output}</td>
                                    <td className={`px-4 py-3 text-sm ${getStatusColor(row.contextAdherence)}`}>
                                        {row.contextAdherence}
                                    </td>
                                    <td className={`px-4 py-3 text-sm ${getStatusColor(row.completeness)}`}>
                                        {row.completeness}
                                    </td>
                                    <td className="px-4 py-3 text-sm text-gray-900">{row.attribution}</td>
                                    <td className={`px-4 py-3 text-sm ${getStatusColor(row.utilization)}`}>
                                        {row.utilization}
                                    </td>
                                    <td className={`px-4 py-3 text-sm ${getStatusColor(row.correctness)}`}>
                                        {row.correctness}
                                    </td>
                                    <td className="px-4 py-3 text-sm text-gray-900">{row.latency}</td>
                                    <td className="px-4 py-3 text-sm text-gray-900">{row.cost}</td>
                                    <td className="px-4 py-3 text-sm text-gray-900">{row.pii}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default ModalSettings;