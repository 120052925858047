function UpgradeNow() {
    return (
        <>
            {/* <div style={{ width: 'calc(100% - 360px)', position: 'absolute', bottom: '0px', }}>
                <p><a href="#" style={{ color: '#7258DD', borderBottom: '1px solid #7258DD' }}>Upgrade now</a> to get GPT-4 level quality, unlimited chats, and the most powerful AI research assistant available</p>
            </div> */}
        </>
    );
}

export default UpgradeNow;