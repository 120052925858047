// src/services/authService.js

import { getLoginCredentials } from './chatService';

const authService = {
    login: async (username, password) => {
        try {
            const response = await getLoginCredentials(username, password);
            // console.log("M1: ", response)
            // console.log("M2: ", response.result)
            // console.log("M3: ", response.user_data)

            if ('access_token' in response) {
                // console.log("localStorage.setItem")
                localStorage.setItem('user', JSON.stringify(response.user_data)); // Save full user data
                localStorage.setItem('access_token', JSON.stringify(response.access_token.replace(/^"|"$/g, ""))); // Save full user data
            }

            return response;
        } catch (error) {
            console.log("Wizard::--> Login Failed: ", error)
            // Show error message to the user
            const response = { result: false, message: "Uh-oh! Something's not quite right with your username or password. Give it another try - we've got this! Please reset your password" }
            return response;
        }
    },
    logout: () => {
        localStorage.removeItem('user');
        localStorage.clear()
    },
    isAuthenticated: () => {
        // console.log("isAuthenticated: ", localStorage.getItem('user'))
        return localStorage.getItem('user') !== null;
    },
    getUser: () => {
        const user = localStorage.getItem('user');
        // console.log("USER:::::::::::::::: ", user)
        return user ? JSON.parse(user) : null;
    },
};

export default authService;
