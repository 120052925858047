import React from 'react'

const DocProcHeading = ({ heading, iconClass }) => {
    return (
        <>

            <div className="position-absolute">
                <h5 className="fw-bold mb-0">Q &amp; A</h5>
                <div className="page-title-right">
                    <ol className="breadcrumb m-0 p-0">
                        <li className="breadcrumb-item fw-bold font-size-12">
                            <a href="#">DocPro</a>
                        </li>
                        <li className="breadcrumb-item fw-bold font-size-12 active ps-0 pe-0">
                            <span className="text-dark"> Chatbot</span>
                        </li>
                    </ol>
                </div>
            </div>


        
        </>
    );
}

export default DocProcHeading;