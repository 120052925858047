// src/components/SettingDetails/UserManagement/UserManagement.js

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import UserRegistrationModal from './UserRegistrationModal';
import UserEditModal from './UserEditModal';
import UserBulkRegistrationModal from './UserBulkRegistrationModal';
import UserRolesModal from './UserRolesModal'; // Import the UserRolesModal
import { getUserDetails, getUserRoles } from '../../../services/chatService';

const UserManagement = () => {
    const [users, setUsers] = useState([]);
    const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
    const [isBulkModalOpen, setIsBulkModalOpen] = useState(false);
    const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [isUserRolesModalOpen, setIsUserRolesModalOpen] = useState(false);
    const [userRoles, setUserRoles] = useState([]);

    const userDetailsFlag = useSelector((state) => state.flags.showUserManagementUserInfo);
    const activeUser = useSelector((state) => state.activeUser);
    const activeUserName = activeUser.username;

    useEffect(() => {
        console.log("get all the users +++++++++++++++++++")
        console.log("userDetailsFlag: ", userDetailsFlag)
        const fetchUserDetails = async () => {
            try {
                const userDetails = await getUserDetails();
                console.log("userDetails: ", userDetails)
                const formattedUserDetails = userDetails.map((user, index) => ({
                    id: users.length + index + 1, // Generating new IDs
                    name: user.name,
                    username: user.username,
                    password: user.password,
                    initial: user.initial,
                    user_role: user.user_role,
                    created_by: user.created_by,
                }));
                setUsers(prevUsers => [...prevUsers, ...formattedUserDetails]);
            } catch (error) {
                console.error("Error fetching users details:", error);
            }
        };

        const fetchUserRoles = async () => {
            try {
                const roles = await getUserRoles();
                console.log("roles: ", roles)
                setUserRoles(roles);
            } catch (error) {
                console.error("Error fetching user roles:", error);
            }
        }

        if (userDetailsFlag) {
            fetchUserDetails();
            fetchUserRoles();
        }
    }, [userDetailsFlag]);

    // -----------------------------------------------------------------------------------
    const handleAddUser = () => {
        setIsAddUserModalOpen(true);
    };

    const addUserToList = (newUser) => {
        setUsers((prevUsers) => [...prevUsers, { id: prevUsers.length + 1, ...newUser }]);
    };
    // -----------------------------------------------------------------------------------
    const handleEditUser = (user) => {
        setCurrentUser(user);
        setIsEditUserModalOpen(true);
    };

    const handleUserUpdate = (updatedUser) => {
        setUsers(users.map(user =>
            user.id === currentUser.id ? { ...user, ...updatedUser } : user
        ));
        // setIsEditUserModalOpen(false); // Close modal after update
    };
    // -----------------------------------------------------------------------------------
    const handleBulkInsert = () => {
        setIsBulkModalOpen(true);
    };
    // -----------------------------------------------------------------------------------
    const handleUserRoles = () => {
        setIsUserRolesModalOpen(true);
    };
    // -----------------------------------------------------------------------------------

    return (
        <div style={{ padding: '15px' }}>
            <div className="card card-bg card-br p-3">
                <div className="d-flex" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <h5 className='mb-0'>User Management</h5>
                    <div>
                        <button className='btn btn-primary btn-sm me-2' onClick={handleUserRoles}>User Roles</button>
                        <button className='btn btn-primary btn-sm me-2' onClick={handleAddUser}>Add New User</button>
                        <button className='btn btn-primary btn-sm me-2' onClick={handleBulkInsert}>Bulk Insert</button>
                    </div>
                </div>

                {users.length > 0 && (
                    <div className='pe-0' style={tbodyContainerStyle}>
                        <table className="mt-3" style={tableStyle}>
                            <thead className='pt-2 pb-2 ps-2 pe-2' style={{ height: '35px', backgroundColor: '#f5f5f5' }}>
                                <tr>
                                    <th className='ps-2' style={{ width: '500px' }}>Name</th>
                                    <th>Username</th>
                                    <th>Initial</th>
                                    <th>Role</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map(user => (
                                    <tr className='ps-2' style={{ height: '40px' }} key={user.id}>
                                        <td className='ps-2'>{user.name}</td>
                                        <td>{user.username}</td>
                                        <td>{user.initial}</td>
                                        <td>{user.user_role}</td>
                                        <td>
                                            <button className='btn btn-white btn-sm' style={{ border: '1px solid #f2f2f2' }} onClick={() => handleEditUser(user)}><i className='fas fa-edit'></i></button>
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>
                )}

                {/* User Registration Modal */}
                <UserRegistrationModal
                    isOpen={isAddUserModalOpen}
                    onRequestClose={() => setIsAddUserModalOpen(false)}
                    activeUserName={activeUserName}
                    addUserToList={addUserToList}
                    userRoles={userRoles}
                />

                {/* User Edit Modal */}
                <UserEditModal
                    isOpen={isEditUserModalOpen}
                    onRequestClose={() => setIsEditUserModalOpen(false)}
                    currentUser={currentUser}
                    activeUserName={activeUserName}
                    handleUserUpdate={handleUserUpdate}
                    userRoles={userRoles}
                />

                {/* Use the UserBulkRegistrationModal component */}
                <UserBulkRegistrationModal
                    isOpen={isBulkModalOpen}
                    onRequestClose={() => setIsBulkModalOpen(false)}
                    activeUserName={activeUserName}
                    users={users}
                    setUsers={setUsers}
                />

                {/* User Roles Modal */}
                <UserRolesModal
                    isOpen={isUserRolesModalOpen}
                    onRequestClose={() => setIsUserRolesModalOpen(false)}
                    userRoles={userRoles}
                />
            </div>
        </div>
    );
};

// Styles
const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '20px',
    tableLayout: 'auto', // Ensure columns adjust to content
};

const buttonStyle = {
    padding: '5px 10px',
    margin: '5px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '4px',
};

const tbodyContainerStyle = {
    maxHeight: '500px',
    overflowY: 'scroll',
};

export default UserManagement;
