import React from 'react';

function Breadcrumb({ title, homeLink, currentPage }) {
    return (
        // <div style={{position: 'absolute'}}>
        <div>
            <h5 className="mb-0" style={{fontWeight: '500'}}>{title}</h5>
            {/* Page title start here */}
            <div className="page-title-right">
                <ol className="breadcrumb m-0 p-0">
                    <li className="breadcrumb-item font-size-12" style={{fontWeight: '500'}}>
                        <a href={homeLink}>{title}</a>
                    </li>
                    <li className="breadcrumb-item font-size-12 active ps-0 pe-0"  style={{fontWeight: '500'}}>
                        <span className="text-dark"> {currentPage}</span>
                    </li>
                </ol>
            </div>
        </div>
    );
}

export default Breadcrumb;
