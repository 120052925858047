import { useSelector, useDispatch } from 'react-redux';
import HistoryContent from './Q&A/HistoryContent';
import ModelType from './ModelType';
import ThemeCustomize from './ThemeCustomize';
import UserCustomizeMessage from './UserCustomizeMessage';
import UserDropdown from './UserDropdown';
import RaiseQuestion from './Q&A/RaiseQuestion';
import UpgradeNow from './UpgradeNow';
import Logout from './Logout';
import MLTaskRaiseQuestion from './MLTask/MLTaskRaiseQuestion';
import ChatEngine from './DocProc/ChatEngine';
// import Indexing from './DocProc/Indexing';
// import PerformingOCR from './DocProc/PerformingOCR';
// import Translation from './DocProc/Translation';
import DocProModal from './DocProc/DocProModal';
import DocProProjects from './DocProc/DocProProjects';
import SettingDetails from './SettingDetails/SettingDetails';
import DocumentIntelligence from './DocumentIntelligence';
import bg_image from '../assets/images/bg.png'
import { setActiveHistoryTab } from '../store/actions';
import Breadcrumb from '../utils/Breadcrumb';

function RightContent() {
    // alert("RightContent.js")

    const isSidebarCollapsed = useSelector((state) => state.flags.isSidebarCollapsed);
    const showStreamlitFlag = useSelector((state) => state.flags.showStreamlit);

    const selectedMLTask = useSelector((state) => state.selectedMLTask);

    const showHelpDetailsFlag = useSelector((state) => state.flags.showHelpDetails);
    const showActivityDetailsFlag = useSelector((state) => state.flags.showActivityDetails);
    const showDocumentIntelligenceFlag = useSelector((state) => state.flags.showDocumentIntelligence);

    const showSettingDetailsFlag = useSelector((state) => state.flags.showSettingDetails);
    const showMLTaskFlag = useSelector((state) => state.flags.showMLTask);
    const showChatEngineFlag = useSelector((state) => state.flags.showChatEngine);
    const showIndexingFlag = useSelector((state) => state.flags.showIndexing);
    const showPerformingOCRFlag = useSelector((state) => state.flags.showPerformingOCR);
    const showTranslationFlag = useSelector((state) => state.flags.showTranslation);
    const showDocProModalFlag = useSelector((state) => state.flags.showDocProModal);
    const showDocProProjectsFlag = useSelector((state) => state.flags.showDocProProjects);

    const showUserCustomizeMessage = useSelector((state) => state.flags.showUserCustomizeMessage);
    const showQANewChat = useSelector((state) => state.flags.showQANewChat);
    const activeHistoryTab = useSelector((state) => state.activeHistoryTab);

    const overlayFlag = useSelector((state) => state.flags.overlayFlag);

    console.log("RightContent.js")
    console.log("showUserCustomizeMessage: ", showUserCustomizeMessage)
    console.log("showQANewChat: ", showQANewChat)
    console.log("activeHistoryTab: ", activeHistoryTab)
    console.log("showHelpDetailsFlag: ", showHelpDetailsFlag)
    console.log("showActivityDetailsFlag: ", showActivityDetailsFlag)
    console.log("showSettingDetailsFlag: ", showSettingDetailsFlag)
    console.log("isSidebarCollapsed: ", isSidebarCollapsed)
    console.log("showMLTaskFlag: ", showMLTaskFlag)
    console.log("showStreamlitFlag: ", showStreamlitFlag)
    console.log("showChatEngineFlag: ", showChatEngineFlag)
    console.log("showIndexingFlag: ", showIndexingFlag)
    console.log("showPerformingOCRFlag: ", showPerformingOCRFlag)
    console.log("showTranslationFlag: ", showTranslationFlag)
    console.log("showDocProModalFlag: ", showDocProModalFlag)
    console.log("showDocProProjectsFlag: ", showDocProProjectsFlag)   
    console.log("overlayFlag: ", overlayFlag)
    

    return (
        <>
            <div className={`rightbar-overlay `} style={{ display: overlayFlag ? 'block' : 'none' }}></div>
            <div className="main-content" style={{ marginLeft: isSidebarCollapsed ? '80px' : '240px', transition: 'margin-left 0.3s', position: 'relative', height: '100%' }}>
                <div className="page-content">
                    <div className="container-fluid" style={{ paddingTop: '64px', paddingLeft: '35px' }}>
                        {/* <div className="container-fluid"> */}
                        {/* {showMLTaskFlag ? (<MLTaskConversationDisplay />) : (<MLTaskRaiseQuestion />)} */}
                        {showMLTaskFlag && <MLTaskRaiseQuestion />}
                        {/* {showStreamlitFlag && <Streamlit />} */}
                        {showChatEngineFlag && <ChatEngine />}
                        {/* {showIndexingFlag && <Indexing />} */}
                        {/* {showPerformingOCRFlag && <PerformingOCR />} */}
                        {/* {showTranslationFlag && <Translation />} */}
                        {showDocProProjectsFlag && <DocProProjects />}
                        {showDocProModalFlag && <DocProModal />}
                        {showDocumentIntelligenceFlag && <DocumentIntelligence />}
                        {selectedMLTask && <MLTaskRaiseQuestion task={selectedMLTask} />}
                        {showUserCustomizeMessage && <UserCustomizeMessage />}
                        {showSettingDetailsFlag && <SettingDetails />}

                        {
                            (showQANewChat || activeHistoryTab) &&
                            <>
                                {showQANewChat && <Breadcrumb title="Q & A" homeLink="#" currentPage="New Chat" />}
                                {activeHistoryTab && <Breadcrumb title="Q & A" homeLink="#" currentPage="Chat History" />}
                                <HistoryContent />
                                <RaiseQuestion />
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default RightContent;
