// const Loader = () => (
//     <div style={{
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         height: '40px',
//     }}>
//         <style jsx>{`
//       @keyframes pulse {
//         0% { transform: scale(0.8); opacity: 0.5; }
//         50% { transform: scale(1); opacity: 1; }
//         100% { transform: scale(0.8); opacity: 0.5; }
//       }
//       .dot {
//         width: 10px;
//         height: 10px;
//         background-color: #666;
//         border-radius: 50%;
//         margin: 0 5px;
//         animation: pulse 1.5s infinite ease-in-out;
//       }
//       .dot:nth-child(2) { animation-delay: 0.2s; }
//       .dot:nth-child(3) { animation-delay: 0.4s; }
//     `}</style>
//         <div className="dot"></div>
//         <div className="dot"></div>
//         <div className="dot"></div>
//     </div>
// );

// export default Loader;

const Loader = () => (
    <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '20px',
        opacity: 1, // Optional for transition effect
        transition: 'opacity 0.5s ease-in-out', // Optional for fade effect
    }}>
        <div className="dot" style={dotStyle}></div>
        <div className="dot" style={{ ...dotStyle, animationDelay: '0.2s' }}></div>
        <div className="dot" style={{ ...dotStyle, animationDelay: '0.4s' }}></div>
        <style>
            {`
            @keyframes pulse {
                0% { transform: scale(0.8); opacity: 0.5; }
                50% { transform: scale(1); opacity: 1; }
                100% { transform: scale(0.8); opacity: 0.5; }
            }
            .dot {
                width: 10px;
                height: 10px;
                background-color: #666;
                border-radius: 50%;
                margin: 0 5px;
                animation: pulse 1.5s infinite ease-in-out;
            }
            `}
        </style>
    </div>
);

const dotStyle = {
    width: '10px',
    height: '10px',
    backgroundColor: '#666',
    borderRadius: '50%',
    margin: '0 5px',
    animation: 'pulse 1.5s infinite ease-in-out'
};

export default Loader;
