// src/components/SettingDetails/UserManagement/UserRegistrationModal.js

import React, { useState } from 'react';
import CustomModal from './Modal';
import { saveNewUser } from '../../../services/chatService';

const UserRegistrationModal = ({ isOpen, onRequestClose, activeUserName, addUserToList, userRoles }) => {
    const [formData, setFormData] = useState({
        fullname: '',
        username: '',
        password: '',
        confirmPassword: '',
        initial: '',
        user_role: ''
    });

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [id]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission behavior

        if (formData.password !== formData.confirmPassword) {
            alert("Passwords do not match!");
            return;
        }

        const newUserInfo = {
            name: formData.fullname,
            username: formData.username,
            password: formData.password,
            initial: formData.initial,
            user_role: formData.user_role,
            created_by: activeUserName
        };

        console.log("newUserInfo: ", newUserInfo)

        // Save the new user to the backend
        try {
            const response = await saveNewUser([newUserInfo]);
            console.log("MD1: ", response)
            console.log("MD2: ", response.result)

            alert(response.result);

            // Update the parent component's users list
            addUserToList(newUserInfo);

            // Close the modal after the user is successfully saved
            onRequestClose();
        } catch (error) {
            console.error("Error saving the new user details: ", error);
        }
    };

    return (
        <CustomModal isOpen={isOpen} onRequestClose={onRequestClose}>
            <h4 style={{ marginBottom: "25px" }}>Register New User</h4>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Full Name"
                    id="fullname"
                    value={formData.fullname}
                    onChange={handleInputChange}
                    style={{ marginBottom: "25px" }}
                    required // Make the field required
                    autoComplete="name" // Suggested autocomplete value for full name
                />
                <br />
                <input
                    type="text"
                    placeholder="Username"
                    id="username"
                    value={formData.username}
                    onChange={handleInputChange}
                    style={{ marginBottom: "25px" }}
                    required // Make the field required
                    autoComplete="username" // Suggested autocomplete value for username
                />
                <br />
                <input
                    type="password"
                    placeholder="Password"
                    id="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    style={{ marginBottom: "25px" }}
                    required // Make the field required
                    autoComplete="new-password" // Suggests that this is a new password input
                />
                <br />
                <input
                    type="password"
                    placeholder="Confirm Password"
                    id="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                    style={{ marginBottom: "25px" }}
                    required // Make the field required
                    autoComplete="new-password" // Suggests that this is a new password confirmation input
                />
                <br />
                <input
                    type="text"
                    placeholder="Initial"
                    id="initial"
                    value={formData.initial}
                    onChange={handleInputChange}
                    style={{ marginBottom: "25px" }}
                    autoComplete="off" // Turn off autocomplete for this field
                />
                <br />
                {/* Dropdown for User Role */}
                <select
                    id="user_role"
                    value={formData.user_role}
                    onChange={handleInputChange}
                    style={{ marginBottom: "25px" }}
                    required // Make the field required
                >
                    <option value="">Select Role</option>
                    {Array.isArray(userRoles) && userRoles.length > 0 ? (
                        userRoles.map((role, index) => (
                            <option key={index} value={role.user_role}>
                                {role.user_role}
                            </option>
                        ))
                    ) : (
                        <option value="" key="no-roles">No roles available</option>
                    )}
                </select>
                <br />
                <button type="submit">
                    Save
                </button>
            </form>
        </CustomModal>
    );
};

export default UserRegistrationModal;
