// src/BodyAttributes.js

import React, { useEffect } from 'react';

const BodyAttributes = () => {
    useEffect(() => {
        // Set attributes
        document.body.setAttribute('data-sidebar', 'dark');
        // Set styles
        document.body.style.overflow = 'hidden';

        // Cleanup function to reset attributes and styles if needed
        return () => {
            document.body.removeAttribute('data-sidebar');
            document.body.style.overflow = '';
        };
    }, []);

    return null; // This component does not render anything
};

export default BodyAttributes;
