import React, { useRef, useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { Mic, X, Send } from 'lucide-react';
import { useSelector } from 'react-redux';

const QuestionInputForm = forwardRef(({
    onSubmit,
    isLoading,
    triggerQuestion,
    placeholder = "Enter a prompt here"
}, ref) => {
    const inputRef = useRef(null);
    const [isListening, setIsListening] = useState(false);
    const [recognition, setRecognition] = useState(null);
    const [isSpeechSupported, setIsSpeechSupported] = useState(true);

    const showTraceFlag = useSelector((state) => state.flags.showTrace);
    const isSidebarCollapsed = useSelector((state) => state.flags.isSidebarCollapsed)

    // Expose the clearInput method to parent components
    useImperativeHandle(ref, () => ({
        clearInput: () => {
            if (inputRef.current) {
                inputRef.current.value = '';
            }
        }
    }));

    useEffect(() => {
        if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
            const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
            const newRecognition = new SpeechRecognition();
            newRecognition.continuous = false;
            newRecognition.lang = 'en-US';
            setRecognition(newRecognition);
        } else {
            setIsSpeechSupported(false);
            console.warn('Speech recognition is not supported in this browser.');
        }
    }, []);

    useEffect(() => {
        if (triggerQuestion) {
            inputRef.current.value = triggerQuestion;
            handleSubmit({ preventDefault: () => { } });
        }
    }, [triggerQuestion]);

    const checkMicrophonePermission = async () => {
        try {
            await navigator.mediaDevices.getUserMedia({ audio: true });
            return true;
        } catch (err) {
            console.error('Microphone permission denied:', err);
            alert("Microphone permission is required for voice input.");
            return false;
        }
    };

    const toggleListening = async () => {
        if (isListening) {
            stopListening();
        } else {
            const hasPermission = await checkMicrophonePermission();
            if (hasPermission) {
                startListening();
            }
        }
    };

    const startListening = () => {
        if (recognition) {
            recognition.start();
            setIsListening(true);

            recognition.onresult = (event) => {
                const transcript = event.results[0][0].transcript;
                const currentText = inputRef.current.value;
                const updatedText = currentText ? `${currentText} ${transcript}` : transcript;
                inputRef.current.value = updatedText;
            };

            recognition.onerror = (event) => {
                console.error('Speech recognition error', event.error);
                setIsListening(false);
            };

            recognition.onend = () => {
                setIsListening(false);
            };
        }
    };

    const stopListening = () => {
        if (recognition) {
            recognition.stop();
            setIsListening(false);
        }
    };

    const clearInput = () => {
        if (inputRef.current) {
            inputRef.current.value = '';
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const question = inputRef.current?.value;
        if (question && question.trim() !== '') {
            onSubmit(question);
            if (!triggerQuestion) {
                clearInput();
            }
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div
                className={`qa-search-container search ${isSidebarCollapsed ? 'sidebar-closed' : 'sidebar-open'
                    }`}
            >
                <div className="search-input-container" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                    <input
                        id="inputText"
                        autoComplete="off"
                        type="text"
                        className="form-control qa-search"
                        placeholder={isLoading ? '' : placeholder}
                        ref={inputRef}
                        disabled={isLoading}
                        style={{
                            width: showTraceFlag && isSidebarCollapsed
                                ? '94.5%'
                                : showTraceFlag
                                    ? '82.5%'
                                    : isSidebarCollapsed
                                        ? '100vw'
                                        : '100%',
                            marginLeft: showTraceFlag && isSidebarCollapsed
                                ? '-13.3%'
                                : showTraceFlag
                                    ? '-0.5%'
                                    : isSidebarCollapsed
                                        ? '-16.4%'
                                        : '-0.4%'
                        }}
                    />
                    <div style={{
                        position: 'absolute',
                        right: '22px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '21px'
                    }}>
                        <button
                            type="button"
                            className="btn btn-secondary clear-btn"
                            onClick={clearInput}
                            disabled={isLoading}
                            aria-label="Clear input"
                            style={{
                                background: 'transparent',
                                border: 'none',
                                padding: 0,
                            }}
                        >
                            <X className="text-white" size={18} />
                        </button>
                        <button
                            className="btn btn-primary plane"
                            type="submit"
                            disabled={isLoading}
                            aria-label="Submit input"
                            style={{
                                background: 'transparent',
                                border: 'none',
                                padding: 0,
                            }}
                        >
                            <Send className="text-white" size={18} />
                        </button>
                        <button
                            className="btn btn-primary mic"
                            type="button"
                            onClick={toggleListening}
                            disabled={isLoading || !isSpeechSupported}
                            aria-label="Voice input"
                            style={{
                                background: 'transparent',
                                border: 'none',
                                padding: 0,
                            }}
                        >
                            <Mic className="text-white" size={18} />
                        </button>
                    </div>
                </div>
            </div>

            {isListening && (
                <div style={{ position: 'absolute', right: '250px', bottom: '47px', color: 'green' }}>
                    Listening...
                </div>
            )}
        </form>
    );
});

export default QuestionInputForm;

