import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthContext } from '../contexts/AuthContext';
import { updateExclusiveFlags } from '../utils/reduxUtils';
import {
    setFlag,
    setUserQuestion,
    setClearQuestionAndResponse,
    setActiveHistoryTab
} from '../store/actions';
import { Pointer } from 'lucide-react';

const Header = () => {
    const dropdownRef = useRef(null);

    const [searchQuery, setSearchQuery] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const isSidebarCollapsed = useSelector((state) => state.flags.isSidebarCollapsed)
    const activeUser = useSelector((state) => state.activeUser);
    const activeUserName = activeUser.username;

    const { logout } = useContext(AuthContext);
    const dispatch = useDispatch();

    const handleSearchChange = (e) => setSearchQuery(e.target.value);

    const handleSearchSubmit = (e) => {
        e.preventDefault();
    };

    const toggleDropdown = () => {
        setDropdownOpen((prev) => !prev);
    };

    const handleLogout = () => {
        dispatch(setUserQuestion(''));
        dispatch(setClearQuestionAndResponse(''));
        dispatch(setActiveHistoryTab(null));
        dispatch(setFlag('userRaisedQuestionFlag', false));
        updateExclusiveFlags(dispatch, {
            showUserCustomizeMessage: false,
            showQANewChat: false,
            
            userClickedAnyChatHistory: false,

            showHelpDetails: false,
            showActivityDetails: false,
            showSettingDetails: false,

            showMLTask: false,
            showStreamlit: false,

            sidebarCollapsed: true,

            showChatEngine: false,
            showIndexing: false,
            showPerformingOCR: false,
            showTranslation: false,
            showDocProModal: false,
            showDocProProjects: false,
        });

        logout();
    };

    // Close dropdown on click outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleEscapeKey);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, []);

    useEffect(() => {
        const style = document.createElement('style');
        style.innerHTML = `
            .placeholder-color::placeholder {
                color: #627072;
                opacity: 1;
            }
        `;
        document.head.appendChild(style);
        return () => {
            document.head.removeChild(style);
        };
    }, []);

    return (
        <header id="page-topbar">
            <div className="navbar-header">
                <div className="d-flex align-items-center h-54px">
                    <div className="navbar-brand-box" style={{ width: isSidebarCollapsed ? '70px' : '240px' }}>
                        <div className="logo logo-light">
                            {isSidebarCollapsed ? (
                                // <span className="logo-sm">
                                <span>
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/favicon.png`} alt="Logo" style={{ height: '25px', width: '25px' }} />
                                </span>
                            ) : (
                                // <span className="logo-lg">
                                <span>
                                    <img src="/assets/images/wizardwhitelogo.svg" alt="Logo" style={{ height: '35px' }} />
                                </span>
                            )}
                        </div>
                    </div>

                    <form className="app-search d-none d-lg-block position-relative" onSubmit={handleSearchSubmit}>
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className="form-control border-radius-5px w-400px placeholder-color"
                            placeholder="Search anything here"
                            style={{
                                color: "#627072" // Text color                
                            }}
                        />
                        <span className="ri-search-line"></span>
                    </form>
                </div>

                <div className="d-flex align-items-center h-54px">
                    <div className="d-flex h-30px align-items-center br-right me-3">
                        <i className="ri-translate-2 header-text font-size-18"></i>
                        <select className="header-select form-select pt-0 pb-0">
                            <option value="eng">Eng</option>
                            <option value="spa">Spa</option>
                            <option value="ita">Ita</option>
                        </select>
                    </div>

                    <div className="d-flex h-30px align-item-center br-right">
                        {/* <i className="ri-moon-line header-text font-size-18 pe-3"></i> */}
                        <i className="ri-notification-3-line header-text font-size-18 pe-3"></i>
                    </div>

                    <div className="dropdown d-inline-block user-dropdown" ref={dropdownRef}>
                        <button
                            type="button"
                            className="btn header-item waves-effect"
                            id="page-header-user-dropdown"
                            onClick={toggleDropdown}
                            aria-haspopup="true"
                            aria-expanded={dropdownOpen}
                            style={{ overflow: 'hidden', position: 'relative' }}
                        >
                            <div className="d-flex align-items-center">
                                <img
                                    className="rounded-circle header-profile-user"
                                    src={`${process.env.PUBLIC_URL}/assets/images/avatar.jpg`}
                                    alt="Header Avatar"
                                />
                                <div>
                                    <p className="d-block ms-1 mb-0 text-start font-size-13">{activeUserName}</p>
                                    <p className="d-block mb-0 header-text ms-1 font-size-12 text-start">
                                        {new Date().toLocaleDateString('en-US', {
                                             weekday: 'long',
                                             day: '2-digit',
                                             month: 'short',
                                             year: 'numeric',
                                        })}
                                    </p>
                                </div>
                            </div>
                        </button>
                        {dropdownOpen && (
                            <div className="dropdown-menu dropdown-menu-end top-60px show">
                                <a className="dropdown-item" href="#">
                                    <i className="ri-user-line align-middle me-1"></i> Profile
                                </a>
                                <div className="dropdown-divider"></div>
                                <a style={{cursor:'Pointer'}} className="dropdown-item text-danger" onClick={handleLogout}>
                                    <i className="ri-shut-down-line align-middle me-1 text-danger"></i> Logout
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
