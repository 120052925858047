import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateExclusiveFlags } from '../../utils/reduxUtils';
import { setActiveHistoryTab } from '../../store/actions';

import { Tooltip, tooltipClasses, styled } from '@mui/material';

const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#2B3D44',
        fontSize: "12px",
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#2B3D44',
    },
   
});

const HistoryList = ({ showAll, updateItemsCount, updateVisibleItems, visibleItems }) => {
    const dispatch = useDispatch();
    const activeHistoryTab = useSelector((state) => state.activeHistoryTab);
    const [sortedChatTitles, setSortedChatTitle] = useState([]);
    const chatHistory = useSelector((state) => state.updateChatHistory);
    const listRef = useRef(null);
    const [clickedTab, setClickedTab] = useState(null); // State to track clicked tab

    useEffect(() => {
        if (chatHistory) {
            const sortedKeys = Object.keys(chatHistory).sort((a, b) => {
                const chatIdA = chatHistory[a][0].chatid;
                const chatIdB = chatHistory[b][0].chatid;
                return chatIdB - chatIdA;
            });
            setSortedChatTitle(sortedKeys);
            updateItemsCount(sortedKeys.length);
            updateVisibleItems(showAll ? sortedKeys.length : 2);
        }
    }, [chatHistory, showAll, updateItemsCount, updateVisibleItems]);

    const handleHistoryListClick = (tab) => {
        dispatch(setActiveHistoryTab(tab));
        updateExclusiveFlags(dispatch, {
            userClickedAnyChatHistory: true,
        });

        setClickedTab(tab); // Mark the clicked tab

        // Reset the clickedTab state after a delay (e.g., 300ms)
        setTimeout(() => {
            setClickedTab(null);
        }, 300); // Time for the red background to be visible
    };

    return (
        <div className="pb-0" data-simplebar style={{ maxHeight: '275px', overflowX: 'hidden' }} ref={listRef}>
            <div className="nav flex-column nav-pills" id="question-tab" role="tablist" aria-orientation="vertical">
                {sortedChatTitles.slice(0, visibleItems).map((myTitle) => (
                    <a
                    className={`nav-link lefttab mb-0 hov p-1 ps-3 pe-3 mt-2 font-size-12 ${activeHistoryTab === myTitle ? 'active' : ''} ${clickedTab === myTitle ? 'clicked' : ''} waves-effect`}
                    id={`${myTitle}-tab`}
                    href="#"
                    role="tab"
                    aria-controls={myTitle}
                    aria-selected={activeHistoryTab === myTitle}
                    onClick={() => handleHistoryListClick(myTitle)}
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <i className={`mdi mdi-comment-outline pb-0 custom-style pe-2 ${clickedTab === myTitle ? 'clicked-icon' : ''}`}></i>
                    <span className="ps-0">{myTitle}</span>
                  </a>
                  
                ))}
            </div>
        </div>
    );
};

export default HistoryList;
