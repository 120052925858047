import React, { useState, useRef, useEffect } from 'react';
import { MoreVertical, Edit, Trash, X, Pointer } from 'lucide-react';
import tracelableicon from '../../assets/images/tracelabel.png';
import uploadicon from '../../assets/images/upload.gif';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../utils/Breadcrumb';
import Loader from '../../utils/Loader';
import { AlertNotification } from '../../utils/AlertNotification';
import { LinearProgress } from '@mui/material';

import {
    uploadFilesInDocproProject,
    getDocproDetails,
    deleteDocproProjectFile,
    updateDocproFileStatus,
    performTranslation,
    performOCR
} from '../../services/chatService';
import { setFlag } from '../../store/actions';

import { Box, Typography, CircularProgress } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const DOCUMENT_API_URL = process.env.REACT_APP_DOCUMENT;

const GreenTickIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" fill="#4CAF50" />
    </svg>
);

const DocProModal = () => {
    const activeUser = useSelector((state) => state.activeUser);
    const activeUserName = activeUser.username;
    const activeUserId = activeUser.user_id;

    const selectedDocProProject = useSelector((state) => state.selectedDocProProject);

    const [activeTab, setActiveTab] = useState(0);
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);

    const [dragActive, setDragActive] = useState(false);
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [uploadingFiles, setUploadingFiles] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);

    const [myDocProId, setMyDocProId] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('info');
    const [isButtonsDisabled, setIsButtonsDisabled] = useState(true);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isDocumentIndexEnabled, setIsDocumentIndexEnabled] = useState(false);
    const [isUploadOpen, setIsUploadOpen] = useState(false);


    const [loading, setLoading] = useState(false);


    const dropdownRefs = useRef({});
    const dispatch = useDispatch();

    const [processingDocument, setProcessingDocument] = useState('');

    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [fileToDelete, setFileToDelete] = useState(null);

    // ------------------------------------------ DocPro Translation
    const [isPerformOCROpen, setIsPerformOCROpen] = useState(false);
    const [ocrResult, setOCRResult] = useState('');
    // ------------------------------------------ DocPro Translation

    // ------------------------------------------ DocPro Translation
    const [isTranslateOpen, setIsTranslateOpen] = useState(false);
    const [originalContent, setOriginalContent] = useState('');
    const [translatedContent, setTranslatedContent] = useState('');
    // ------------------------------------------ DocPro Translation

    // ------------------------------------------ Document Indexing
    const [showDocumentIndexAlert, setShowDocumentIndexAlert] = useState(false);
    const [indexingStatus, setIndexingStatus] = useState('');
    const [indexerName, setIndexerName] = useState('');
    const [indexingErrorMessage, setIndexingErrorMessage] = useState('');
    // ------------------------------------------ Document Indexing

    useEffect(() => {
        dispatch(setFlag('overlayFlag', isUploadOpen || isTranslateOpen));
    }, [isUploadOpen, isTranslateOpen]);

    // ===========================================================================
    const fetchFiles = async () => {
        try {
            console.log("selectedDocProProject: ", selectedDocProProject);
            const response = await getDocproDetails(activeUserName, selectedDocProProject);

            if (response?.status === true) {
                const { folder_path, total_files, files } = response.results;

                const formattedFiles = files.map((file) => ({
                    docproid: null,
                    name: file.document_name,
                    status: file.status,
                    updatedOn: new Date(file.last_updated).toLocaleString(),
                    owner: file.owner,
                    lastUpdatedBy: file.last_updated_by,
                    isActive: Boolean(file.is_active),
                }));

                setUploadedFiles(formattedFiles);
            } else {
                console.error("Unexpected response format:", response);
            }
        } catch (error) {
            console.error("Error fetching uploaded files:", error);
        }
    };

    useEffect(() => {
        console.log("page loaded")
        fetchFiles();
    }, [activeUserId, activeUser.username]);
    // ===========================================================================
    // Function to show notification
    const showNotification = (message) => {
        setAlertMessage(message);
        setShowAlert(true);
    };

    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    //             setOpenDropdownIndex(null);
    //         }
    //     };

    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => document.removeEventListener('mousedown', handleClickOutside);
    // }, []);

    const toggleDropdown = (index) => {
        setOpenDropdownIndex(openDropdownIndex === index ? null : index);
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
            const isOutsideClick = Object.values(dropdownRefs.current).every(
                (ref) => ref && !ref.contains(event.target)
            );

            if (isOutsideClick) {
                console.log("Click detected outside the dropdown. Closing.");
                setOpenDropdownIndex(null); // Close all dropdowns
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    // ----------------------------------------------------------------------------------------------

    // Toggle the selection of a row
    // const toggleRowSelection = (index) => {
    //     const newSelectedRows = selectedRows.includes(index)
    //         ? selectedRows.filter((rowIndex) => rowIndex !== index)
    //         : [...selectedRows, index];

    //     setSelectedRows(newSelectedRows);
    //     setIsButtonsDisabled(newSelectedRows.length !== 1); // Enable buttons only when one row is selected

    //     // Check if the selected row has a status of "OCR" for "Document Index"
    //     const selectedFile =
    //         newSelectedRows.length === 1
    //             ? uploadedFiles[newSelectedRows[0]]
    //             : null;

    //     setIsDocumentIndexEnabled(
    //         newSelectedRows.length === 1 && selectedFile?.status === "OCR"
    //     );
    // };
    const toggleRowSelection = (index) => {
        // Check if the current index is already selected
        const isCurrentlySelected = selectedRows.includes(index);

        // Toggle selection: deselect if already selected, otherwise select
        const newSelectedRows = isCurrentlySelected ? [] : [index];

        setSelectedRows(newSelectedRows);
        setIsButtonsDisabled(newSelectedRows.length !== 1); // Enable buttons only when one row is selected

        // Check if the selected row has a status of "OCR" for "Document Index"
        const selectedFile = newSelectedRows.length === 1 ? uploadedFiles[newSelectedRows[0]] : null;

        setIsDocumentIndexEnabled(
            newSelectedRows.length === 1 && selectedFile?.status === "OCR"
        );
    };


    // ----------------------------------------------------------------------------------------------
    const RefreshMyDocuments = async () => {
        setSelectedRows([]);
        setUploadedFiles([]);
        await fetchFiles();
    };
    // ----------------------------------------------------------------------------------------------
    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        const files = [...e.dataTransfer.files];
        const validFiles = files.filter(file =>
            file.type === "application/pdf" || file.type === "text/csv"
        );

        if (validFiles.length) {
            setFilesToUpload(prev => [...prev, ...validFiles.map(file => ({
                file,
                name: file.name
            }))]);
        }
    };

    const handleFileInput = (e) => {
        const files = [...e.target.files];
        const validFiles = files.filter(file =>
            file.type === "application/pdf" || file.type === "text/csv"
        );

        if (validFiles.length) {
            setFilesToUpload(prev => [...prev, ...validFiles.map(file => ({
                file,
                name: file.name
            }))]);
        }
    };

    const handleUpload = async () => {
        if (filesToUpload.length === 0) return;

        // let alrtMsg = '';
        setUploadingFiles(
            filesToUpload.map((file) => ({
                name: file.name,
                progress: 0,
            }))
        );

        try {
            // Simulate upload progress for each file
            for (const file of filesToUpload) {
                await simulateFileUpload(file); // Simulate upload for the file

                // Call your actual upload function here
                const response = await uploadFilesInDocproProject(
                    activeUserName,
                    selectedDocProProject,
                    [file]
                );

                if (response.status) {
                    // Update uploadedFiles state
                    setUploadedFiles((prev) => [...prev, { name: file.name, progress: 100 }]);
                } else {
                    // alrtMsg = response.message || 'File upload failed.';
                    setAlertType('error');
                    setAlertMessage("File upload failed");


                }
            }

            // Fetch the latest uploaded files
            await fetchFiles(); // Refresh uploaded files list
        } catch (error) {
            console.error('Error during file upload:', error.message);
            setAlertType('error');
            setAlertMessage(`Upload failed: ${error.message}`);
            // alrtMsg = `Upload failed: ${error.message}`;
        } finally {
            setFilesToUpload([]); // Clear filesToUpload
            setUploadingFiles([]); // Clear uploadingFiles
            setShowAlert(true);
            // setAlertMessage(alrtMsg || 'Upload completed successfully!');
            setAlertMessage("Upload completed successfully");
            setAlertType('success');
            setIsUploadOpen(false);
        }
    };

    // Simulate file upload with progress updates
    const simulateFileUpload = (file) =>
        new Promise((resolve) => {
            let progress = 0;

            const interval = setInterval(() => {
                setUploadingFiles((prev) =>
                    prev.map((f) =>
                        f.name === file.name ? { ...f, progress: Math.min(progress + 20, 100) } : f
                    )
                );

                progress += 20;

                if (progress >= 100) {
                    clearInterval(interval);
                    resolve(); // Resolve the promise once upload completes
                }
            }, 1000); // Increment progress every 1 second
        });


    const removeUploadingFile = (fileName) => {
        setUploadingFiles(prev => prev.filter(file => file.name !== fileName));
    };
    // ===========================================================================

    const handleDeleteConfirmation = (name) => {
        console.log("Delete confirmation triggered for Name:", name);
        setFileToDelete(name);
        setDeleteConfirmationOpen(true); // Open the confirmation dialog
        setOpenDropdownIndex(null)
    };

    // Method to confirm the delete action
    const confirmDelete = async () => {
        console.log("Confirm delete triggered for file:", fileToDelete);
        if (!fileToDelete) return; // Ensure there's a file selected

        try {
            const response = await deleteDocproProjectFile(activeUserName, selectedDocProProject, fileToDelete);
            if (response?.status) {
                setUploadedFiles([]); // Clear uploaded files
                await fetchFiles(); // Fetch the updated file list
                // showNotification("File deleted successfully.");
                setShowAlert(true);
                // setAlertMessage(alrtMsg || 'Upload completed successfully!');
                setAlertMessage("File deleted successfully");
                setAlertType('success');
            } else {
                // showNotification("Failed to delete the file. Please try again.");
                console.error("Error deleting file:", response.message);
                setAlertType('error');
                setAlertMessage(response.message);
                setShowAlert(true);
            }
        } catch (error) {
            console.error("Error during file deletion:", error);
            // showNotification(`Error: ${error.message}`);
            setAlertType('error');
            setAlertMessage(`Error: ${error.message}`);
            setShowAlert(true);
        } finally {
            setDeleteConfirmationOpen(false); // Close the dialog
            setFileToDelete(null); // Clear the file to delete
        }
    };

    // -------------------------------------------------------------------- Perform OCR start
    const handlePerformOCR = async () => {
        if (selectedRows.length === 1) {
            const selectedFile = uploadedFiles[selectedRows[0]];
            const selectedFileName = selectedFile.name;
            console.log("selectedFile.name: ", selectedFileName)
            setProcessingDocument(selectedFileName);
            setIsPerformOCROpen(true);

            setLoading(true);
            setOCRResult('');

            try {
                const folderPaths = [`${selectedDocProProject}/${selectedFileName}`];

                const response = await performOCR(activeUserName, folderPaths);

                if (response?.status) {
                    if (response.results?.length > 0) {
                        const firstResult = response.results[0];
                        if (firstResult.extracted_text) {
                            setOCRResult(firstResult.extracted_text);

                            RefreshMyDocuments();
                        } else {
                            setOCRResult('No text extracted or invalid response from OCR service.');
                        }
                    }
                }
                else {
                    setOCRResult(`OCR failed: ${response.message}`);
                }
            } catch (err) {
                setOCRResult(`OCR failed: ${err.message}`);
            } finally {
                setLoading(false);
            }
        }
    };

    const handlePerformOCRClose = async () => {
        setIsPerformOCROpen(false);
        setOCRResult('');
        setLoading(false);
    };
    // -------------------------------------------------------------------- Perform OCR end

    // -------------------------------------------------------------------- Document Translation start
    const handleTranslate = async () => {
        if (selectedRows.length === 1) {
            const selectedFile = uploadedFiles[selectedRows[0]];
            setProcessingDocument(selectedFile.name);
            setIsTranslateOpen(true);
            handleTranslation()
        }
    }

    const handleTranslation = async () => {
        setLoading(true);
        try {
            const folderPaths = [`${selectedDocProProject}/${processingDocument}`];

            const response = await performTranslation(activeUserName, folderPaths);

            if (response?.status) {
                if (response.results?.length > 0) {
                    const firstResult = response.results[0];
                    setOriginalContent(firstResult.original_content);
                    setTranslatedContent(firstResult.translated_content);

                    RefreshMyDocuments();
                }
            }
            else {
                setTranslatedContent('Translation failed: ' + response.message);
            }
        } catch (err) {
            setTranslatedContent('Translation failed: ' + err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleTranslationClose = async () => {
        setIsTranslateOpen(false);
        setOriginalContent('');
        setTranslatedContent('');
        setLoading(false);
    };
    // -------------------------------------------------------------------- Document Translation close

    // -------------------------------------------------------------------- Document Indexing start
    const startIndexing = async () => {
        setIndexingErrorMessage('');
        setIndexingStatus('running');
        try {
            const response = await fetch(`${DOCUMENT_API_URL}/api/v1/indexing/start`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Failed to start indexing');
            }

            const data = await response.json();
            setIndexerName(data.indexer_name);
        } catch (error) {
            setIndexingStatus('error');
            setIndexingErrorMessage(error.message);
            console.error('Indexing error:', error);
        }
    };

    const checkStatus = async () => {
        if (!indexerName) return;

        try {
            const response = await fetch(`${DOCUMENT_API_URL}/api/v1/indexing/status/${indexerName}`);
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Failed to get indexer status');
            }

            const data = await response.json();
            setIndexingStatus(data.status.toLowerCase());

        } catch (error) {
            setIndexingErrorMessage(error.message);
            console.error('Status check error:', error);
        }
    };

    useEffect(() => {
        let interval;
        if (indexingStatus === 'running' || indexingStatus.toLowerCase() === 'inprogress') {
            interval = setInterval(checkStatus, 5000);
        }
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [indexingStatus, indexerName]);

    useEffect(() => {
        const handleSuccess = async () => {
            try {
                const response = await updateDocproFileStatus(activeUserId, myDocProId, 'Extracted');
                if (response?.status === "true") {
                    setUploadedFiles([]);
                    await fetchFiles();
                }
            } catch (error) {
                console.error('Error fetching uploaded files:', error);
            }
        };

        if (indexingStatus === 'success') {
            handleSuccess();
        }
    }, [indexingStatus]);


    const handleDocumentIndex = async () => {
        if (selectedRows.length === 1) {
            const selectedFile = uploadedFiles[selectedRows[0]];
            setProcessingDocument(selectedFile.name);
            setMyDocProId(selectedFile.docproid);

            // dispatch(setFlag('overlayFlag', true));

            setShowDocumentIndexAlert(true)
            await startIndexing()
        }
    };
    // -------------------------------------------------------------------- Document Indexing end


    return (
        <>
            <div id="modal" className="tab_content">
                {/* <div className="container-fluid"> */}
                <div className="d-flex justify-content-between">
                    <Breadcrumb title="DocPro" homeLink="#" currentPage={`${selectedDocProProject} ${uploadedFiles && uploadedFiles.length > 0 ? `(${uploadedFiles.length} Docs)` : ''}`} />

                    <div>
                        <button
                            style={{ border: "1px solid #C1C2C7", backgroundColor: "#f8f9fa", color: "#000000", opacity: "1" }}
                            className="btn btn-light btn-sm perocr me-1 centered-button"
                            onClick={handlePerformOCR}
                            disabled={isButtonsDisabled}
                        >
                            Perform OCR
                        </button>
                        <button
                            style={{ border: "1px solid #C1C2C7", backgroundColor: "#f8f9fa", color: "#000000", opacity: "1" }}
                            className="btn btn-light btn-sm right-bar-toggle3 me-1 centered-button"
                            disabled={isButtonsDisabled}
                            onClick={handleTranslate}
                        >
                            Translate
                        </button>
                        {/* <button
                            style={{ border: "1px solid #C1C2C7", backgroundColor: "#f8f9fa", opacity: "1" }}
                            className="btn btn-light btn-sm me-1 centered-button"
                            disabled={!isDocumentIndexEnabled}
                            onClick={handleDocumentIndex}
                        >
                            Document Index
                        </button> */}

                        <button
                            style={{ paddingBottom: "7px", opacity: "1" }}
                            className="btn btn-warning btn-sm text-dark right-bar-toggle centered-button "
                            onClick={() => setIsUploadOpen(true)}
                        >
                            Upload Documents
                        </button>
                    </div>
                </div>

                <div>
                    <input
                        type="text"
                        placeholder="Search by Document name"
                        className="form-control font-size-12 doc-search mt-2 "
                        style={{
                            width: '340px',
                            height: '37px',
                            backgroundColor: '#fff',
                            marginBottom: "10px",
                            border: '1px solid #E1E0E3',
                            borderRadius: "4px"

                        }}
                    />
                    <div className="doc-br"></div>
                    <ul className="nav nav-tabs nav-tabs-custom nav-justified w-fit-content" role="tablist">
                        <li className="nav-item">
                            <a className={` nav-link ${activeTab === 0 ? 'active' : ''}`} data-bs-toggle="tab" onClick={() => setActiveTab(0)} role="tab" aria-selected="false" tabIndex="1" style={{ cursor: 'Pointer', width: "90px" }}>
                                <span className="d-none d-sm-block white-space-nowrap" style={{ color: "#12262E", fontWeight: "700" }}>All</span>
                            </a>

                        </li>
                        {/* <li className="nav-item">
                            <a className={`w-fit-content nav-link ${activeTab === 1 ? 'active' : ''}`} data-bs-toggle="tab" onClick={() => setActiveTab(1)} role="tab" aria-selected="false" tabIndex="2" style={{ cursor: 'Pointer' }}>
                                <span className="d-none d-sm-block white-space-nowrap">PDF Documents</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={`w-fit-content nav-link ${activeTab === 2 ? 'active' : ''}`} data-bs-toggle="tab" onClick={() => setActiveTab(2)} role="tab" aria-selected="false" tabIndex="3" style={{ cursor: 'Pointer' }}>
                                <span className="d-none d-sm-block white-space-nowrap">Perform OCR</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={`w-fit-content nav-link ${activeTab === 3 ? 'active' : ''}`} data-bs-toggle="tab" onClick={() => setActiveTab(3)} role="tab" aria-selected="false" tabIndex="4" style={{ cursor: 'Pointer' }}>
                                <span className="d-none d-sm-block white-space-nowrap">Translated Documents</span>
                            </a>
                        </li> */}
                    </ul>
                    <div className="tab-content" style={{ position: 'relative', bottom: '5px', overflowX: 'auto' }}>
                        {activeTab === 0 && (
                            <div
                                style={{
                                    maxHeight: 'calc(96vh - 198px)', // Adjust this based on your header/footer size
                                    minHeight: '370px',
                                    overflowY: 'auto', // Enable vertical scrolling
                                    position: 'relative',
                                }}
                            >
                                <table className="scroll-vertical-datatable table dt-responsive nowrap w-100 scroll-tab">
                                    <thead
                                        className="table-head fw-bold"
                                        style={{
                                            backgroundColor: "rgba(227, 229, 230, 1)",
                                            position: "sticky",
                                            top: -1, // Ensures the header stays at the top during scrolling
                                            zIndex: 2, // Keeps the header above the tbody content
                                        }}
                                    >
                                        <tr>
                                            <th>
                                                <input type="checkbox" className="form-check  thcheck" />
                                            </th>
                                            <th style={{ fontWeight: "500" }}>Document Name</th>
                                            <th style={{ fontWeight: "500" }}>Status</th>
                                            <th style={{ fontWeight: "500" }}>Owner</th>
                                            <th style={{ fontWeight: "500" }}>Updated On</th>
                                            <th style={{ fontWeight: "500" }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {uploadedFiles.map((file, index) => (
                                            <tr
                                                key={index}
                                                className={selectedRows.includes(index) ? "highlight" : ""}
                                            >
                                                <td className="pt-2" style={{ verticalAlign: "middle", color: "#6B7280", fontWeight: "100" }}>
                                                    <input
                                                        type="checkbox"
                                                        className="form-check"
                                                        checked={selectedRows.includes(index)} // Ensure only the current row is checked
                                                        onChange={() => toggleRowSelection(index)}
                                                    />
                                                </td>
                                                <td className="pt-11px" style={{ verticalAlign: "middle", color: "#6B7280", fontWeight: "100" }}>
                                                    {file.name}
                                                </td>
                                                <td className="pt-2" style={{ verticalAlign: "middle" }}>
                                                    <div
                                                        className="w-fit-content pt-1 text-dark pb-1 ps-2 pe-2 bg-ext  d-flex justify-content-center align-items-center"
                                                        style={{ width: "60%", borderRadius: "19px" }}
                                                    >
                                                        {file.status}
                                                    </div>
                                                </td>
                                                <td className="pt-11px" style={{ verticalAlign: "middle", color: "#6B7280", fontWeight: "100" }}>
                                                    {file.owner}
                                                </td>
                                                <td className="pt-11px" style={{ verticalAlign: "middle", color: "#6B7280", fontWeight: "100" }}>
                                                    {file.updatedOn}
                                                </td>
                                                <td className="pt-11px" style={{ verticalAlign: "middle", color: "#6B7280", fontWeight: "100" }}>
                                                    <div
                                                        className="dropdown d-none d-sm-inline-block"
                                                        ref={(el) => (dropdownRefs.current[index] = el)}
                                                    >
                                                        <MoreVertical
                                                            className="cursor-pointer"
                                                            size={18}
                                                            onClick={(e) => {
                                                                e.stopPropagation(); // Prevent event from bubbling up
                                                                toggleDropdown(index); // Toggle dropdown
                                                            }}
                                                        />
                                                        <div
                                                            className={`dropdown-menu dropdown-menu-end ${openDropdownIndex === index ? "show" : ""
                                                                }`}
                                                            style={{
                                                                minWidth: "100px",
                                                                position: "absolute",
                                                                transform: "translate3d(-130px, 0px, 0px)",
                                                            }}
                                                            onClick={(e) => e.stopPropagation()}
                                                        >
                                                            <a
                                                                href="#"
                                                                className="dropdown-item notify-item"
                                                                style={{ paddingLeft: "14px" }}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    handleDeleteConfirmation(file.name);
                                                                }}
                                                            >
                                                                <p style={{ display: "flex", alignItems: "center", marginBottom: 0, fontSize: "12px" }}>
                                                                    <Trash style={{ paddingRight: "4px" }} size={18} />
                                                                    <span style={{ marginTop: "4px" }}>Delete</span>
                                                                </p>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>


                {/* Upload Sidebar */}
                {isUploadOpen && (
                    // style={{marginTop: '62px'}}
                    <div className="right-bar p-3 " >
                        <div data-simplebar className="h-100">
                            <div className="d-flex align-item-center justify-content-between">
                                <h6 className=" mb-0 fw-medium text-dark">Upload Document</h6>
                                <X className="cursor-pointer ms-auto" onClick={() => setIsUploadOpen(false)} size={16} />
                            </div>
                            <div className="trace-label p-2 d-flex align-item-center mt-1 w-fit-content" style={{ marginBottom: "14px" }}><img src={tracelableicon} className="w-14px me-2" alt="trace label" />
                                <h6 className="font-size-10 mb-0 text-dark">Add your document to begin processing and exploration</h6>
                            </div>

                            <div className={`upload-file p-3 mt-2 border-2 border-dashed rounded-lg p-6 text-center  cursor-pointer ${dragActive ? 'border-warning bg-warning bg-opacity-10' : 'border-gray-300'}`}
                                onDragEnter={handleDrag}
                                onDragLeave={handleDrag}
                                onDragOver={handleDrag}
                                onDrop={handleDrop}
                                onClick={() => document.getElementById('file-upload').click()}
                            >
                                <input
                                    type="file"
                                    className="hidden"
                                    id="file-upload"
                                    multiple
                                    accept=".pdf,.csv"
                                    onChange={handleFileInput}
                                    style={{ display: 'none' }}
                                />
                                <div className="text-center">
                                    <img src={uploadicon} className="w-100px" />
                                </div>
                                <h6 className="text-dark fw-medium text-center mb-1">Drag and Drop files here</h6>
                                <p className="font-size-10 text-center mb-2">Files supported : CSV, PDF</p>
                                <p className="font-size-10 text-center mb-0">Maximum size : 5MB</p>


                            </div>

                            <div className="px-3">
                                {/* Display files ready for upload */}
                                {filesToUpload.length > 0 && (
                                    <div className="mt-4">
                                        {filesToUpload.map((file, index) => (
                                            <div
                                                key={index}
                                                className="p-2 mb-2"
                                            >
                                                <div
                                                    className="d-flex align-items-center justify-content-between"
                                                    style={{ fontSize: '11px', color: '#12262E', fontWeight: '100' }}
                                                >
                                                    <p className="mb-0 text-truncate" style={{ marginLeft: "-8px" }}>{file.name}</p>
                                                    <X
                                                        className="cursor-pointer ms-2"
                                                        style={{ marginRight: "-17px" }}
                                                        size={16}
                                                        onClick={() =>
                                                            setFilesToUpload((prev) =>
                                                                prev.filter((f) => f.name !== file.name)
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}

                                {/* Display uploading files */}
                                {uploadedFiles.length > 0 && (
                                    <div className="mt-4" style={{ width: "22vw", position: "relative", left: "-6px" }}>
                                        {uploadingFiles.map((file, index) => (
                                            <div
                                                key={index}
                                            >
                                                {/* <p className="mb-0 text-truncate">{file.name}</p> */}
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={file.progress || 0} // Ensure you pass the correct progress value here
                                                    sx={{
                                                        height: 5,
                                                        mt: 1, // Add some margin on top for spacing
                                                        backgroundColor: '#DDF5F5',
                                                        '& .MuiLinearProgress-bar': {
                                                            backgroundColor: '#5DCDC6', // Customize bar color (optional)
                                                        },
                                                    }}
                                                />
                                                {/* <X
                                                    className="cursor-pointer ms-2"
                                                    size={16}
                                                    onClick={() => removeUploadingFile(file.name)}
                                                /> */}
                                            </div>
                                        ))}
                                    </div>
                                )}

                                <div className="position-absolute  " style={{ bottom: "0px", height: "28px", width: "90%" }} >
                                    <div className="d-flex " style={{ gap: "10px" }}>
                                        <button
                                            style={{ marginLeft: "-16px" }}
                                            className="btn btn-light btn-sm centered-button"
                                            onClick={() => setIsUploadOpen(false)}
                                        >
                                            Close
                                        </button>

                                        <button
                                            htmlFor="file-upload"
                                            className="btn btn-warning btn-sm text-dark centered-button"
                                            style={{ marginRight: "-14px" }}
                                            onClick={handleUpload}
                                        >
                                            Upload
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}


                {deleteConfirmationOpen && (
                    <div className="alert-dismissible pe-3 fade performocr-status fw-medium align-item-center show" role="alert">
                        <div className="d-flex align-items-center pe-4">
                            <span className="me-2 text-warning">
                                <i className="fas fa-exclamation-triangle"></i>
                            </span>
                            <span>Are you sure you want to delete the document <strong>{fileToDelete}</strong>?</span>
                        </div>
                        <button
                            type="button"
                            className="btn-close p-2"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                            onClick={() => setDeleteConfirmationOpen(false)}
                        ></button>
                        <div className="d-flex justify-content-end pt-2">
                            <a href="#" className="btn btn-sm btn-dark" onClick={() => setDeleteConfirmationOpen(false)}>Close</a>
                            <a href="#" className="btn btn-sm btn-dark ms-2" onClick={confirmDelete}>Delete</a>
                        </div>
                    </div>
                )}


                {/* Alert Notification */}
                {/* {showAlert && (
                    <>
                        <div className="alert-dismissible pe-3 fade performocr-status fw-medium align-item-center show" role="alert">
                            <div className="pe-4">{alertMessage}</div>
                            <button type="button" className="btn-close p-2" data-bs-dismiss="alert" aria-label="Close" onClick={() => setShowAlert(false)}></button>
                            <div className="d-flex justify-content-end pt-2">
                                <a href="#" className="btn btn-sm btn-dark right-bar-toggle2 preview" onClick={() => setShowAlert(false)}>Close</a>
                            </div>
                        </div>
                    </>
                )} */}


                {showAlert && (
                    <AlertNotification
                        message={alertMessage}
                        type={alertType}
                        onClose={() => setShowAlert(false)}
                        autoClose={true}
                        autoCloseTime={3000}
                    />
                )}


                {/* Document Index Alert Notification */}
                {showDocumentIndexAlert && (
                    <>
                        <div className="alert-dismissible pe-3 fade performocr-status fw-medium align-item-center show" role="alert">
                            {indexingStatus && (
                                <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
                                    <Typography variant="body1" sx={{ mr: 1 }}>
                                        Status:
                                    </Typography>
                                    {indexingStatus === 'completed' || indexingStatus === 'success' ? (
                                        <GreenTickIcon />
                                    ) : indexingStatus === 'error' ? (
                                        <ErrorIcon color="error" />
                                    ) : (
                                        <CircularProgress size={20} />
                                    )}
                                    <Typography variant="body1" sx={{ ml: 1 }}>
                                        {indexingStatus.charAt(0).toUpperCase() + indexingStatus.slice(1)}
                                    </Typography>
                                </Box>
                            )}
                            {indexerName && (
                                <Typography variant="body1" sx={{ mt: 1 }}>
                                    Indexer Name: {indexerName}
                                </Typography>
                            )}
                            {/* <div className="d-flex justify-content-end pt-2">
                                <a href="#" className="btn btn-sm btn-dark right-bar-toggle2 preview" onClick={() => setShowDocumentIndexAlert(false)}>Close</a>
                            </div> */}

                            <div className="d-flex justify-content-end pt-2">
                                <a
                                    href="#"
                                    className={`btn btn-sm btn-dark right-bar-toggle2 preview ${indexingStatus !== 'success' ? 'disabled' : ''}`}
                                    onClick={(e) => {
                                        if (indexingStatus === 'success') {
                                            // dispatch(setFlag('overlayFlag', false));
                                            setShowDocumentIndexAlert(false);
                                        } else {
                                            e.preventDefault(); // Prevent interaction if the button is disabled
                                        }
                                    }}
                                    style={{ pointerEvents: indexingStatus === 'success' ? 'auto' : 'none' }}
                                >
                                    Close
                                </a>
                            </div>
                        </div>
                    </>
                )}


                {/* Perfom OCR */}
                {isPerformOCROpen && (
                    (
                        <div className="right-bar p-3" style={{ width: '50%' }}>
                            <div data-simplebar className="h-100">
                                <div className="d-flex align-item-center justify-content-between">
                                    <h6 className="mb-0 fw-medium text-dark">Perform OCR</h6>
                                    <X className="cursor-pointer ms-auto" onClick={handlePerformOCRClose} />
                                </div>
                                <div className="trace-label p-2 d-flex align-item-center mt-2 w-fit-content">
                                    <img src={tracelableicon} className="w-14px me-2" alt="trace label" />
                                    <h6 className="font-size-10 mb-0 text-dark">Convert PDF or documents into editable text with precision</h6>
                                </div>

                                <div className="d-flex align-item-center justify-content-between pt-2">
                                    <h6 className="text-dark font-size-12 mb-1 pt-2">Selected Document</h6>
                                </div>
                                <p className="font-size-10 text-dark mt-0 mb-0">{processingDocument}</p>

                                <div className="d-flex align-item-center justify-content-between pt-2">
                                    <h6 className="text-dark font-size-12 mb-0 pt-2 pb-1">OCR Results</h6>
                                </div>

                                <div
                                    className="trans-box p-3 custom-scrollbar"
                                    style={{
                                        flex: 1,
                                        overflowY: 'auto',

                                    }}
                                >
                                    <p className="mb-0 font-size-12 ">
                                        {loading ? <Loader /> : ocrResult}
                                    </p>
                                </div>

                                <div className="d-flex  gap-2 mt-4">
                                    <button className="btn btn-light btn-sm" onClick={handlePerformOCRClose}>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                )}

                {/* Translate Notification */}
                {isTranslateOpen && (
                    (
                        <div className="right-bar p-3" style={{ width: '50%' }}>
                            <div data-simplebar className="h-100">
                                <div className="d-flex align-item-center justify-content-between">
                                    <h6 className="mb-0 fw-medium text-dark">Translate Document</h6>
                                    <X className="cursor-pointer ms-auto" onClick={handleTranslationClose} />
                                </div>
                                <div className="trace-label p-2 d-flex align-item-center mt-2 w-fit-content">
                                    <img src={tracelableicon} className="w-14px me-2" alt="trace label" />
                                    <h6 className="font-size-10 mb-0 text-dark">Effortlessly convert your document into the language you need</h6>
                                </div>

                                <div className="d-flex align-item-center justify-content-between pt-2">
                                    <h6 className="text-dark font-size-12 mb-1 pt-2">Selected Document</h6>
                                </div>
                                <p className="font-size-10 text-dark mt-0 mb-0">{processingDocument}</p>

                                <div className="d-flex align-item-center justify-content-between pt-2">
                                    <h6 className="text-dark font-size-12 mb-0 pt-2 pb-1">Original Content :</h6>
                                </div>

                                <div
                                    className="trans-box p-3 "
                                    style={{
                                        flex: 1,
                                        overflowY: 'auto',
                                        height: "30vh"
                                    }}
                                >
                                    <p className="mb-0 font-size-12">
                                        {loading ? <Loader /> : originalContent}
                                    </p>
                                </div>

                                <div className="d-flex  align-item-center justify-content-between pt-2">
                                    <h6 className="text-dark font-size-12 mb-0 pt-2 pb-1">Translated Content :</h6>
                                </div>

                                <div
                                    className="trans-box p-3"
                                    style={{
                                        flex: 1,
                                        overflowY: 'auto',
                                        height: "30vh"
                                    }}
                                >
                                    <p className="mb-0 font-size-12">
                                        {loading ? <Loader /> : translatedContent}
                                    </p>
                                </div>

                                <div className="d-flex  gap-2 mt-4">
                                    <button className="btn btn-light btn-sm" onClick={handleTranslationClose}>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                )}
            </div>
        </>
    );
};

export default DocProModal;