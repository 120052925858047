import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconQuestion from '../../utils/IconQuestion';
import { setFlag } from '../../store/actions';
import tracelableicon from '../../assets/images/tracelabel.png';

const RenderingTheQuestion = (props) => {
    const dispatch = useDispatch();
    const { question, sqlQuery } = props;

    const [showSidebar, setShowSidebar] = useState(false); // State to control sidebar visibility

    const activeUser = useSelector((state) => state.activeUser);
    const activeUserName = activeUser.username;

    const toggleSidebar = () => {
        console.log("mahesh2-overlay: ", showSidebar)
        
        setShowSidebar(!showSidebar);
        dispatch(setFlag('overlayFlag', !showSidebar));
    };

    return (
        <>
            <div className="d-flex">
                <IconQuestion />
                <div
                    className={`ps-2 w-100 ${showSidebar ? 'main-content-shrinked' : ''}`} // Apply class when sidebar is shown
                >
                    <p className="mb-0 font-size-12 pt-1">{activeUserName}</p>
                    <div className="d-flex align-item-center pt-1 justify-content-between">
                        <div>
                            <h6 className="text-dark font-size-12 fw-bold mb-0">{question}</h6>
                        </div>
                        <div>
                            <p className="font-size-12 mb-0 d-flex align-item-center">
                                <span onClick={toggleSidebar} className="view-trace cursor-pointer">
                                    Show Trace
                                    <i className="fas fa-chevron-right ps-1"></i>
                                </span>
                                {/* <i className="far fa-edit font-size-12 ps-2"></i> */}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Sidebar Section */}
            {showSidebar && (
                <div
                    className="show-trace"
                    style={{
                        position: 'absolute',
                        // top: '6.9%',
                        right: 0,
                        width: '340px',
                        // height: '100%',
                        backgroundColor: '#fff',
                        // boxShadow: '0px 0px 5px 0px #e4e4e4',
                        boxShadow: ' -3px 3px 4px 1.1px #e4e4e4',
                        
                        zIndex: 9999,
                        padding: '20px',
                        overflowY: 'auto',
                        transition: 'transform 0.3s ease-in-out',
                        display: showSidebar ? 'block' : 'none'
                    }}
                >
                    <div className="d-flex align-item-center justify-content-between">
                        <h6 className="trace-head mb-0">Trace</h6>
                        <i className="mdi mdi-close text-dark font-size-14 close-trace cursor-pointer" onClick={toggleSidebar}></i>
                    </div>

                    <div className="trace-label p-2 d-flex align-item-center mt-3 w-fit-content">
                        <img src={tracelableicon} className="w-14px me-2" alt="trace label" />
                        <h6 className="font-size-10 mb-0 text-dark">Logs : {question}</h6>
                    </div>

                    <div style={{ position: 'relative', marginTop: '10px', border: '1px solid #ccc' }}>
                        <pre style={{
                            margin: 0,
                            fontFamily: 'monospace',
                            fontSize: '14px',
                            lineHeight: '20px',
                            backgroundColor: '#fff',
                            overflow: 'scroll',
                            whiteSpace: 'pre-wrap',
                            height: '54vh'
                        }}>
                            {sqlQuery.split('\n').map((line, index) => (
                                <div key={index} style={{ display: 'flex' }}>
                                    <div style={{
                                        width: '29px',
                                        textAlign: 'right',
                                        padding: '0 17px',
                                        color: '#666',
                                        backgroundColor: '#f5f5f5',
                                        userSelect: 'none',
                                        borderRight: '1px solid #ddd',
                                        position: 'sticky', // Makes the index column sticky
                                        left: 0, // Keeps it fixed on the left
                                        zIndex: 1, // Ensures it stays on top of the content
                                    }}>
                                        {index + 1}
                                    </div>
                                    <div style={{
                                        padding: '0 8px',
                                        flex: 1,
                                        whiteSpace: 'break-spaces'
                                    }}>
                                        {line.split(' ').map((word, wordIndex) => {
                                            let color = 'inherit';

                                            if (/^(SELECT|FROM|AS|LIMIT|OFFSET)$/i.test(word)) {
                                                color = '#9333ea'; // SQL keywords
                                            } else if (word.startsWith('--') || word.startsWith('#') || word.startsWith('/*')) {
                                                color = '#16a34a'; // Comments
                                            } else if (word.startsWith("'") || word.startsWith('"')) {
                                                color = '#ca8a04'; // Strings
                                            } else if (/^\d+/.test(word)) {
                                                color = '#2563eb'; // Numbers
                                            } else if (word.startsWith('@')) {
                                                color = '#dc2626'; // Variables
                                            }

                                            return (
                                                <span key={wordIndex} style={{ color }}>
                                                    {word}{' '}
                                                </span>
                                            );
                                        })}
                                    </div>
                                </div>
                            ))}
                        </pre>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button onClick={toggleSidebar} className="btn btn-sm btn-secondary mt-3" style={{ position: 'absolute', bottom: '30px',left:'15px',backgroundColor:"white",color:"black",fontWeight:"500" }}>
                            Close
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

// Memoize the question to prevent re-renders
export default React.memo(RenderingTheQuestion);
