function IconAnswer() {
    return (
        // <div>
        //     <img
        //         // src="assets/images/explorer.png"
        //         src="/favicon.png"
        //         style={{ width: '30px', marginTop: '0.3em', backgroundColor: '#fff', border: '1px solid #ededed',padding: '7px',borderRadius: '30px' }}
        //         alt="Explorer"
        //     />
        // </div>

        <div>
            <div className="ans-profile">
                <i className="mdi mdi-cards-diamond"></i>
            </div>
        </div>
    );
}

export default IconAnswer;
