import React from 'react';

class ResizeObserverErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Check specifically for ResizeObserver errors
    if (error.message.includes('ResizeObserver loop completed with undelivered notifications')) {
      return { hasError: true };
    }
    return { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    // Ignore ResizeObserver errors as we know they are benign
    if (!error.message.includes('ResizeObserver loop completed with undelivered notifications')) {
      // Log other errors or handle them as needed
      console.error('Caught an error:', error, errorInfo);
    }
  }

  render() {
    // Fallback UI or just render children as normal
    if (this.state.hasError) {
      return null; // Or return a custom fallback UI
    }

    return this.props.children;
  }
}

export default ResizeObserverErrorBoundary;
