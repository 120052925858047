import { useSelector } from 'react-redux';

function IconQuestion() {
    // const activeUser = useSelector((state) => state.activeUser);
    // const userInitial = activeUser.initial;

    return (
        // <div className="avatar-xs me-2">
        //     <span
        //         className="avatar-title text-white rounded-circle btn dropdown-toggle"
        //         style={{
        //             backgroundColor: '#11047A',
        //             fontSize: '10px',
        //             height: '30px',
        //             width: '30px',
        //             display: 'flex',
        //             alignItems: 'center',
        //             justifyContent: 'center',
        //             fontWeight: 'bold',
        //             marginTop: '0.3em'
        //         }}
        //     >
        //         {userInitial}
        //     </span>
        // </div>

        <div>
            <img 
                src="assets/images/avatar.jpg" 
                alt="Avatar" 
                className="body-profile"
            >
            </img>
        </div>
    );
}

export default IconQuestion;
