// src/store/reducers.js
import { combineReducers } from '@reduxjs/toolkit';

const initialState = {
    activeUser: {
        username: '',
        user_id: '',
        name: '',
        initial: '',
        access_rules: '',
    },
    questionsAndResponses: [],
    chatHistory: null,
    customizeMessage: null,
    configurationInfo: {
        length: 200,
        temperature: 0.7,
        includeInternetSearch: false,
        includeWebScraping: false,
        webScrapingUrl: '',
    },
};

const initialMLTaskState = {
    mlTaskHistory: [],
    selectedMLTask: null,
};

const initialFlagsState = {
    isSidebarCollapsed: false,

    userRaisedQuestionFlag: false,
    userClickedAnyChatHistory: false,
    showUserCustomizeMessage: false,
    showQANewChat: false,
    showHelpDetails: false,
    showActivityDetails: false,
    showSettingDetails: false,
    showMLTask: false,
    showDocumentIntelligence: false,

    showChatEngine: false,
    showIndexing: false,
    showPerformingOCR: false,
    showTranslation: false,
    showDocProModal: false,
    showDocProProjects: false,
    
    showStreamlit: false,

    showUserManagementUserInfo: false,
    showTrace: false,
    overlayFlag: false,
};

// Single reducer to handle all boolean flags
const flagsReducer = (state = initialFlagsState, action) => {
    switch (action.type) {
        case 'SET_FLAG':
            const { flagName, value } = action.payload;
            return { ...state, [flagName]: value };
        default:
            return state;
    }
};

const updateChatHistory = (state = initialState.chatHistory, action) => {
    switch (action.type) {
        case 'UPDATE_CHAT_HISTORY':
            // console.log("action",  action.type)
            // console.log("action",  action.payload)
            // const ob = action.payload
            return action.payload
        // other cases
        default:
            return state;
    }
};


const updateUserCustomizeMessage = (state = initialState.customizeMessage, action) => {
    switch (action.type) {
        case 'UPDATE_USER_CUSTOMIZE_MESSAGE':
            return action.payload
        // other cases
        default:
            return state;
    }
};

const questionsAndResponses = (state = initialState.questionsAndResponses, action) => {
    switch (action.type) {
        case 'ADD_QUESTION_AND_RESPONSE':
            return [...state, action.payload];
        case 'ADD_PARTIAL_RESPONSE':
            return state.map(qa => {
                // Check if the current qa object has a defined response and response_id
                if (qa.response && qa.response.response_id === action.payload.responseId) {
                    // If the response_id matches, update the text property
                    return {
                        ...qa,
                        response: {
                            ...qa.response,
                            // text: qa.response.text + action.payload.text
                            text: action.payload.text,
                            sql_query: qa.response.sql_query
                                ? qa.response.sql_query + '\n' + action.payload.sql_query
                                : action.payload.sql_query,
                        }
                    };
                } else {
                    // If the response_id doesn't match, return the original qa object
                    return qa;
                }
            });
        case 'COMPLETE_RESPONSE':
            return state.map(qa => {
                // Check if the current qa object has a defined response and response_id
                if (qa.response && qa.response.response_id === action.payload.responseId) {
                    // If the response_id matches, update the text property
                    return {
                        ...qa,
                        response: {
                            ...qa.response,
                            // text: qa.response.text + action.payload.text,
                            text: action.payload.text,
                            chartCode: action.payload.chartCode,
                            sql_query: action.payload.sql_query,
                            query_results: action.payload.query_results,
                            desc: action.payload.desc,
                            source: action.payload.source
                        }
                    };
                } else {
                    // If the response_id doesn't match, return the original qa object
                    return qa;
                }
            });
        case 'SET_CLEAR_QUESTION_AND_RESPONSE':
            return initialState.questionsAndResponses;
        default:
            return state;
    }
};

const activeUser = (state = initialState.activeUser, action) => {
    switch (action.type) {
        case 'SET_USER_INFO':
            return {
                // ...state,
                username: action.payload.username,
                user_id: action.payload.user_id,
                name: action.payload.name,
                initial: action.payload.initial,
                access_rules: action.payload.access_rules
            };
        case 'CLEAR_USER_INFO':
            return initialState.activeUser;
        default:
            return state;
    }
};

const currentConfigurationInfo = (state = initialState.configurationInfo, action) => {
    switch (action.type) {
        case 'SET_CONFIGURATION_INFO':
            return {
                length: action.payload.length,
                temperature: action.payload.temperature,
                includeInternetSearch: action.payload.includeInternetSearch,
                includeWebScraping: action.payload.includeWebScraping,
                webScrapingUrl: action.payload.webScrapingUrl,
            };
        default:
            return state;
    }
};

const activeHistoryTab = (state = null, action) => {
    switch (action.type) {
        case 'SET_ACTIVE_HISTORY_TAB':
            return action.payload;
        default:
            return state;
    }
};

const activeMLHistoryTab = (state = null, action) => {
    switch (action.type) {
        case 'SET_ACTIVE_ML_HISTORY_TAB':
            return action.payload;
        default:
            return state;
    }
};

const userQuestion = (state = '', action) => {
    switch (action.type) {
        case 'SET_USER_QUESTION':
            return action.payload;
        default:
            return state;
    }
};

const chatBotResponse = (state = null, action) => {
    switch (action.type) {
        case 'SET_CHAT_BOT_RESPONSE':
            return action.payload;
        default:
            return state;
    }
};

const triggerRaiseQuestion = (state = null, action) => {
    switch (action.type) {
        case 'TRIGGER_RAISE_QUESTION':
            return action.payload;
        default:
            return state;
    }
};

const selectedDocProProject = (state = null, action) => {
    switch (action.type) {
        case 'SELECTED_DOCPRO_PROJECT':
            return action.payload;
        default:
            return state;
    }
};

const mlTaskReducer = (state = initialMLTaskState, action) => {
    switch (action.type) {
        case 'ADD_CONVERSATION_MLTASK':
            return {
                ...state,
                mlTaskHistory: [...state.mlTaskHistory, action.payload],
            };
        case 'UPDATE_TASK_LOGS':
            const { taskIndex, logIndex, newLogData } = action.payload;
            return {
                ...state,
                mlTaskHistory: state.mlTaskHistory.map((task, i) =>
                    i === taskIndex
                        ? {
                            ...task,
                            logs: task.logs.map((log, j) =>
                                j === logIndex ? newLogData : log
                            )
                        }
                        : task
                )
            };
        case 'LOAD_TASK_INTO_RIGHT_CONTENT':
            return {
                ...state,
                selectedMLTask: action.payload,
            };
        default:
            return state;
    }
};



export default combineReducers({
    activeUser,
    activeHistoryTab,
    userQuestion,
    chatBotResponse,
    questionsAndResponses,
    updateChatHistory,
    triggerRaiseQuestion,
    updateUserCustomizeMessage,
    currentConfigurationInfo,
    selectedDocProProject,
    mlTask: mlTaskReducer,

    activeMLHistoryTab,
    flags: flagsReducer,
});
