import { useEffect, useContext, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AuthContext } from '../contexts/AuthContext';
import sessionTimeout from '../assets/images/Sessionout.gif';
import {
    setUserQuestion,
    setClearQuestionAndResponse,
    setActiveHistoryTab
} from '../store/actions';
import { updateExclusiveFlags } from '../utils/reduxUtils';

const TIMEOUT_DURATION = 15 * 60 * 1000; // 15 minutes in milliseconds

export const useAutoLogout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { logout } = useContext(AuthContext);
    const timeoutId = useRef();
    const isWarningShown = useRef(false);

    const performLogout = useCallback(() => {
        dispatch(setUserQuestion(''));
        dispatch(setClearQuestionAndResponse(''));
        dispatch(setActiveHistoryTab(null));
        updateExclusiveFlags(dispatch, {
            showUserCustomizeMessage: false,
            showQANewChat: false,
            userClickedAnyChatHistory: false,
            showHelpDetails: false,
            showActivityDetails: false,
            showSettingDetails: false,
            showMLTask: false,
            showStreamlit: false,
            sidebarCollapsed: true,
            showChatEngine: false,
            showIndexing: false,
            showPerformingOCR: false,
            showTranslation: false,
            showDocProModal: false,
            showDocProProjects: false,
        });
        logout();
        navigate('/login');
    }, [dispatch, logout, navigate]);

    const showWarning = useCallback(() => {
        if (isWarningShown.current) return;
        isWarningShown.current = true;

        // Check if the overlay already exists, and remove it if so
        const existingOverlay = document.querySelector('.session-overlay');
        if (existingOverlay) {
            existingOverlay.remove();
        }

        // Enable the overlay
        const overlayDiv = document.createElement('div');
        overlayDiv.classList.add('session-overlay'); // Add class for easier selection
        overlayDiv.style.cssText = `
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
           
             background-color: rgba(0, 0, 0, 0.8); /* Darker overlay */
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 9998;
        `;
        document.body.appendChild(overlayDiv);

        // Create the alert content container
        const alertDiv = document.createElement('div');
        alertDiv.style.cssText = `
            text-align: center;
            color: #fff;
            font-size: 18px;
            padding: 20px;
        `;
        overlayDiv.appendChild(alertDiv);

        // Add warning text (before GIF display)
        alertDiv.innerHTML = `
            <p>Session timeout warning. Please wait...</p>
        `;

        // Delay the GIF display
        setTimeout(() => {
            alertDiv.innerHTML = `
                <img 
                    src="${sessionTimeout}"  
                    alt="Timeout Warning" 
                    style="width: 22vw; height: auto; margin-bottom: 15px;"
                />
                <h3 style="margin-bottom: 15px; color:white">Session Timeout</h3>
                <p style="margin-bottom: 20px; color:#AAB1B">
                Oops! Your session took a little nap due to inactivity. Let's
                    <br />
                    wake it up and get back to work
                    
                </p>
                <button style="
                    // padding: 8px 20px;
                    // background-color: #007bff;
                    // color: white;
                    // border: none;
                    // border-radius: 5px;
                    // cursor: pointer;
                    // width: 100px;
                    background-color: transparent;
                    border: 1px solid #F1BD03;
                    border-radius: 10px;
                    padding: 4px 16px;
                    color: #F1BD03;
                    cursor: pointer;
                    width: 100px;
                            ">OK</button>
                        `;

            // Add click event listener to the OK button
            const okButton = alertDiv.querySelector('button');
            okButton.addEventListener('click', () => {
                alertDiv.remove();
                overlayDiv.remove();
                performLogout();
            });
        });
    }, [performLogout]);


    const resetTimer = useCallback(() => {
        if (timeoutId.current) {
            clearTimeout(timeoutId.current);
        }
        isWarningShown.current = false;

        timeoutId.current = setTimeout(showWarning, TIMEOUT_DURATION);
    }, [showWarning]);

    useEffect(() => {
        const events = [
            'mousemove',
            'mousedown',
            'keydown',
            'touchstart',
            'scroll',
            'click'
        ];

        const handleUserActivity = () => {
            if (!isWarningShown.current) {
                resetTimer();
            }
        };

        events.forEach(event => {
            window.addEventListener(event, handleUserActivity);
        });

        resetTimer(); // Initial timer setup

        return () => {
            events.forEach(event => {
                window.removeEventListener(event, handleUserActivity);
            });
            if (timeoutId.current) {
                clearTimeout(timeoutId.current);
            }
        };
    }, [resetTimer]);

    return resetTimer;
};
