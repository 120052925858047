import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getChatHistory, getUserCustomizeMessage } from '../services/chatService';

const RetrieveChatHistoryFromDB = () => {
    const dispatch = useDispatch();
    const activeUser = useSelector((state) => state.activeUser);
    const activeUserId = activeUser.user_id;
    
    // const fullUrl = window.location.href; // Full URL including protocol, host, and path
    // const hostname = window.location.hostname; // Hostname (e.g., "example.com")
    // const pathname = window.location.pathname; // Path (e.g., "/path/to/page")
    // const protocol = window.location.protocol; // Protocol (e.g., "https:")
    // const port = window.location.port; // Port (e.g., "3000" or "" if default)
    // const searchParams = window.location.search; // Query string (e.g., "?id=123")

    // console.log("Website Address")
    // console.log("Full URL: ", {fullUrl})
    // console.log("Hostname: ", {hostname})
    // console.log("Pathname: ", {pathname})
    // console.log("Protocol: ", {protocol})
    // console.log("Port: ", {port})
    // console.log("Search Params: ", {searchParams})


    useEffect(() => {
        console.log("RetrieveChatHistoryFromDB useEffect called")
        const fetchChatHistory = async () => {
            try {
                const my_data = await getChatHistory(activeUserId);
                // console.log("my_data: ", my_data)
                dispatch({ type: 'UPDATE_CHAT_HISTORY', payload: my_data });
            } catch (error) {
                console.error("Error fetching chat history:", error);
            }
        };

        const fetchUserCustomizeMessage = async () => {
            try {
                const hostname = window.location.hostname;
                console.log("Hostname: " + hostname)

                const my_user_msg_data = await getUserCustomizeMessage(hostname);
                // console.log("my_user_msg_data: ", my_user_msg_data)
                dispatch({ type: 'UPDATE_USER_CUSTOMIZE_MESSAGE', payload: my_user_msg_data });
            } catch (error) {
                console.error("Error fetching UserCustomizeMessage:", error);
            }
        };

        if (activeUserId) {
            fetchChatHistory();
            fetchUserCustomizeMessage();
        }
    }, [activeUserId]);   // }, [dispatch, activeUserId]);

    return null; // Since this component is purely for fetching data, it doesn't render anything
};

export default RetrieveChatHistoryFromDB;
 