// src/contexts/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import authService from '../services/authService';
import { useDispatch, useSelector } from 'react-redux';
import { setUserInfo, clearUserInfo } from '../store/actions';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(authService.isAuthenticated()); // Check localStorage initially
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.activeUser); // Assume your reducer sets this in the state
    
    useEffect(() => {
        const user = authService.getUser();
        
        if (user) {
            console.log("Mahesh3$$$$$$AuthContext")
            console.log("username...........", user.username)
            console.log("user_id...........", user.user_id)
            console.log("name...........", user.name)
            console.log("initial...........", user.initial)
            
            setIsAuthenticated(true);
            // Dispatch user information to the store
            dispatch(setUserInfo(user.username, user.user_id, user.name, user.initial));
        } else {
            setIsAuthenticated(false);
        }
    }, [dispatch]);
    
    useEffect(() => {
        // console.log("Mahesh2.....AuthContext")
        // console.log("userInfo.username: ", userInfo.username)
        // console.log("userInfo.user_id: ", userInfo.user_id)
        // console.log("userInfo.name: ", userInfo.name)
        // console.log("userInfo.initial: ", userInfo.initial)

        if (userInfo && userInfo.user_id) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }, [userInfo]);

    const login = async (username, password) => {
        // console.log("login happen --------------------------AuthContext")
        const response = await authService.login(username, password);
        // console.log("response.result: " + response.result);
        console.log("response.user_data: " + response.user_data);

        if ('access_token' in response) {
            const { username, user_id, name, initial, productsegment } = response.user_data;
            console.log("Mahesh1??????AuthContext")
            console.log("username...........", username)
            console.log("user_id...........", user_id)
            console.log("name...........", name)
            console.log("initial...........", initial)
            console.log("access_rules...........", productsegment)

            dispatch(setUserInfo(username, user_id, name, initial, productsegment));
            setIsAuthenticated(true);
        }

        return response;
    };

    const logout = () => {
        // console.log("logout happen........................................AuthContext")
        authService.logout();
        setIsAuthenticated(false);
        dispatch(clearUserInfo());
         // Reset theme and other settings
         localStorage.removeItem('theme'); // or other settings stored in localStorage
         document.documentElement.setAttribute('data-theme', 'light'); // reset theme to light
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };
