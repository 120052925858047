// src/store/actions.js
export const setUserInfo = (username, user_id, name, initial, access_rules) => ({
    type: 'SET_USER_INFO',
    payload: { username, user_id, name, initial, access_rules },
});

export const setConfigurationInfo = (length, temperature, includeInternetSearch, includeWebScraping, webScrapingUrl) => ({
    type: 'SET_CONFIGURATION_INFO',
    payload: { length, temperature, includeInternetSearch, includeWebScraping, webScrapingUrl },
});

export const clearUserInfo = () => ({
    type: 'CLEAR_USER_INFO'
});

export const setActiveHistoryTab = (tab) => ({
    type: 'SET_ACTIVE_HISTORY_TAB',
    payload: tab,
});

export const setActiveMLHistoryTab = (tab) => ({
    type: 'SET_ACTIVE_ML_HISTORY_TAB',
    payload: tab,
});


export const setUserQuestion = (question) => ({
    type: 'SET_USER_QUESTION',
    payload: question,
});

export const setChatBotResponse = (response) => ({
    type: 'SET_CHAT_BOT_RESPONSE',
    payload: response,
});

// ------------------------------------------------------------------------------
export const addQuestionAndResponse = ({ question, response }) => ({
    type: 'ADD_QUESTION_AND_RESPONSE',
    payload: { question, response },
});

export const addPartialResponse = (responseId, text, chartCode, sql_query, query_results, desc, source) => ({
    type: 'ADD_PARTIAL_RESPONSE',
    payload: { responseId, text, chartCode, sql_query, query_results, desc, source }
});

export const addCompleteResponse = (responseId, text, chartCode, sql_query, desc, source) => ({
    type: 'ADD_COMPLETE_RESPONSE',
    payload: { responseId, text, chartCode, sql_query, desc, source }
});
// ------------------------------------------------------------------------------

export const setUpdateChatHistory = (newHistory) => ({
    type: 'UPDATE_CHAT_HISTORY',
    payload: newHistory
});

export const setUpdateUserCustomizeMessage = (myMessage) => ({
    type: 'UPDATE_USER_CUSTOMIZE_MESSAGE',
    payload: myMessage
});

export const setClearQuestionAndResponse = (response) => ({
    type: 'SET_CLEAR_QUESTION_AND_RESPONSE',
    payload: response,
});

export const setTriggerRaiseQuestion = (question) => ({
    type: 'TRIGGER_RAISE_QUESTION',
    payload: question
});

export const addConversationMLTask = (conversation) => ({
    type: 'ADD_CONVERSATION_MLTASK',
    payload: conversation
});

export const loadTaskIntoRightContent = (task) => ({
    type: 'LOAD_TASK_INTO_RIGHT_CONTENT',
    payload: task,
});

export const updateTaskLogs = (taskId, newLog) => ({
    type: 'UPDATE_TASK_LOGS',
    payload: { taskId, newLog }
});

export const setSelectedDocProProject = (projectName) => ({
    type: 'SELECTED_DOCPRO_PROJECT',
    payload: projectName
});

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++
export const updateExclusiveFlags = (flags) => ({
    type: 'UPDATE_EXCLUSIVE_FLAGS',
    payload: flags
});
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++

export const setFlag = (flagName, value) => ({
    type: 'SET_FLAG',
    payload: { flagName, value },
});