// src/components/SettingDetails/UserManagement/UserBulkRegistrationModal.js

import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import CustomModal from './Modal';
import { saveNewUser } from '../../../services/chatService';

const UserBulkRegistrationModal = ({ isOpen, onRequestClose, activeUserName, users, setUsers }) => {
    const [bulkData, setBulkData] = useState([]);

    // Handle file upload
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            const arrayBuffer = e.target.result;
            const workbook = XLSX.read(new Uint8Array(arrayBuffer), { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            setBulkData(data.slice(1)); // Skip header row
        };

        reader.readAsArrayBuffer(file); // Use readAsArrayBuffer instead of readAsBinaryString
    };

    // Handle bulk save with validation
    const handleBulkSave = async () => {
        const newUserInfoArray = [];

        // Validate and process each row
        for (let i = 0; i < bulkData.length; i++) {
            const row = bulkData[i];
            const [fullname, username, password, initial, user_role] = row;

            // Check if any field is empty
            if (!fullname || !username || !password || !initial || !user_role) {
                alert(`Row ${i + 1} is missing required fields. Please fill in all fields.`);
                return; // Stop processing if validation fails
            }

            // Add the valid user to the newUserInfoArray array
            newUserInfoArray.push({
                id: users.length + i + 1, // Assign unique IDs
                name: fullname,
                username: username,
                password: password,
                initial: initial,
                user_role: user_role,
                created_by: activeUserName
            });
        }

        try {
            // Send the array of new users to the backend
            const response = await saveNewUser(newUserInfoArray);
            console.log("Bulk save response: ", response);

            alert(response.result);

            // Update the parent component's users list with unique IDs
            setUsers(prevUsers => [...prevUsers, ...newUserInfoArray]);
            onRequestClose();
        } catch (error) {
            console.error("Error saving the bulk user details: ", error);
        }
    };


    return (
        <CustomModal isOpen={isOpen} onRequestClose={onRequestClose}>
            <h4>Bulk Import Users</h4>
            <input
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileUpload}
            />
            {/* <button onClick={handleBulkSave} style={buttonStyle}>
                Save
            </button> */}
            <div style={modalContentStyle}>
                <h5>Preview:</h5>
                <div style={tableContainerStyle}>
                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                <th>Full Name</th>
                                <th>Username</th>
                                <th>Password</th>
                                <th>Initial</th>
                                <th>User Role</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bulkData.map((row, index) => (
                                <tr key={index}>
                                    <td>{row[0]}</td>
                                    <td>{row[1]}</td>
                                    <td>{row[2]}</td>
                                    <td>{row[3]}</td>
                                    <td>{row[4]}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <button onClick={handleBulkSave} style={buttonStyle}>
                Save
            </button>
        </CustomModal>
    );
};

// Styles specific to the modal
const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '20px',
    tableLayout: 'auto',
};

const buttonStyle = {
    padding: '5px 10px',
    margin: '5px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '4px',
};

const modalContentStyle = {
    maxWidth: '100%',
    overflow: 'auto',
};

const tableContainerStyle = {
    maxHeight: '300px',
    overflowY: 'auto',
};

export default UserBulkRegistrationModal;
