// Modal.js
import React from 'react';
import ReactDOM from 'react-dom';

const Modal = ({ isOpen, onRequestClose, children }) => {
    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div style={overlayStyle}>
            <div style={modalStyle}>
                <button onClick={onRequestClose} style={closeButtonStyle}>X</button>
                {children}
            </div>
        </div>,
        document.body
    );
};

// Styles for the modal
const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const modalStyle = {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '5px',
    maxWidth: '500px',
    width: '100%',
    position: 'relative',
};

const closeButtonStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    border: 'none',
    background: 'transparent',
    fontSize: '16px',
    cursor: 'pointer',
};

export default Modal;
