import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import Dashboard from './components/Dashboard';

import './App.css';
import './styles.css';
import './assets/css/app-dark.min.css';
import './assets/css/app.min.css';
import './assets/css/bootstrap-dark.min.css';
import './assets/css/bootstrap.min.css';
import './assets/css/icons.min.css';
import './assets/css/custom-styles.css';
import './assets/css/bootstrap.bundle.min.js';


const App = () => {
    return (
        <AuthProvider>
            <Router>
                <Routes>
                    {/* <Route path="/" element={<Login />} /> */}
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<ProtectedRoute component={Dashboard} />} />
                    {/* <Route path="/chat/:chatid" element={<ProtectedRoute component={Dashboard} />} /> */}
                </Routes>
            </Router>
        </AuthProvider>
    );
};

export default App;
