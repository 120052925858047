// src/utils/reduxUtils.js

import { setFlag } from '../store/actions';

export const updateExclusiveFlags = (dispatch, {
    showUserCustomizeMessage = false, 
    showQANewChat = false,

    userClickedAnyChatHistory = false, 

    showHelpDetails = false,
    showActivityDetails = false,
    showSettingDetails = false,

    showMLTask = false, 
    showStreamlit = false,      //now it is not used
    showDocumentIntelligence = false,

    showChatEngine =  false,
    showIndexing = false,
    showPerformingOCR = false,
    showTranslation = false,
    showDocProModal = false,
    showDocProProjects = false,
    
} = {}) => {
    dispatch(setFlag('showUserCustomizeMessage', showUserCustomizeMessage));
    dispatch(setFlag('showQANewChat', showQANewChat));

    dispatch(setFlag('userClickedAnyChatHistory', userClickedAnyChatHistory));

    dispatch(setFlag('showHelpDetails', showHelpDetails));
    dispatch(setFlag('showActivityDetails', showActivityDetails));
    dispatch(setFlag('showSettingDetails', showSettingDetails));
    
    dispatch(setFlag('showMLTask', showMLTask));
    dispatch(setFlag('showStreamlit', showStreamlit));
    dispatch(setFlag('showDocumentIntelligence', showDocumentIntelligence));
    
    dispatch(setFlag('showChatEngine', showChatEngine));
    dispatch(setFlag('showIndexing', showIndexing));
    dispatch(setFlag('showPerformingOCR', showPerformingOCR));
    dispatch(setFlag('showTranslation', showTranslation));
    dispatch(setFlag('showDocProModal', showDocProModal));
    dispatch(setFlag('showDocProProjects', showDocProProjects));
};
