import React, { useState, useEffect } from 'react';

const IframeComponent = ({ url }) => {
  const [height, setHeight] = useState('100vh');

  useEffect(() => {
    const handleResize = () => {
      const windowHeight = window.innerHeight;
      const newHeight = windowHeight - 95; // Subtracting 20px for a small margin
      setHeight(`${newHeight}px`);
    };

    // Set initial height
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="iframe-container" style={{ height: '105vh', overflow: 'hidden' }}>
      <iframe
        src={url}
        title="Embedded Content"
        width="100%"
        height={height}
        style={{ border: 'none' }}
        allowFullScreen
      />
    </div>
  );
};

const App = () => {
  return (
    <div className="App" style={{ height: '100vh', overflow: 'hidden' }}>
      <IframeComponent url="http://4.224.111.208:8501/" />
    </div>
  );
};

export default App;