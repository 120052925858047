// src/componenets/Dashboard.js

import React from 'react';
import LeftSidebar from './LeftSidebar';
import RightContent from './RightContent';
// import ShowTrace from './ShowTrace';
// import BreadcrumbWithTitle from './Breadcrumb-1';
import BodyAttributes from './BodyAttributes';
import RetrieveChatHistoryFromDB from './RetrieveChatHistoryFromDB';
import ResizeObserverErrorBoundary from './ResizeObserverErrorBoundary';
import Header from './Header';
import { useAutoLogout } from '../hooks/UseAutoLogout';
import IntroTour from './Tour/IntroTour';

const Dashboard = () => {
    useAutoLogout();
    // const pages = [
    //     { label: 'Home', path: '#' },
    //     { label: 'Chatbot', path: '#' }
    //   ];





    return (
        <>
            <ResizeObserverErrorBoundary>
                <Header />
                <IntroTour
                    steps={console.log('Tour completed')}
                    onComplete={() => console.log('Tour completed')}
                />

                {/* <div className="d-flex"> */}
                <BodyAttributes />
                <RetrieveChatHistoryFromDB />
                {/* <BreadcrumbWithTitle title="Q & A" items={pages} /> */}
                <LeftSidebar />
                <RightContent />
                {/* <ShowTrace/> */}
                {/* </div> */}
            </ResizeObserverErrorBoundary>
        </>
    );
};

export default Dashboard;
